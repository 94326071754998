import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, } from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useFacilityContext } from '../../../../../../contexts/Facilities/FacilityPageContext';
import { useSpaceContext } from '../../../../../../contexts/Spaces/SpaceContext';
import { PageModes, } from '../../../../../../objects/FCAInterfaces';
import { FacilityAssetSelector } from './FacilityAssetSelector';
import { StandardAssetsDetails } from './StandardAssetsDetails';
export const classHasMultipleAssets = [
    'Flooring',
    'Interior Doors',
    'Exterior Doors',
];
export const StandardAssets = ({ spaceIdentity, spaceName, standardAssetTemplates, canEdit, isEditing, standardAssets, orgIdentity, }) => {
    const { manageFacilityWideAssets: { allFacilityAssetsList }, } = useSpaceContext();
    const { setPageMode } = useFacilityContext();
    const { control } = useFormContext();
    const { fields } = useFieldArray({
        name: 'standardAssets',
        control: control,
    });
    const filterdAssets = [];
    fields.forEach((field) => {
        const standardAsset = standardAssets === null || standardAssets === void 0 ? void 0 : standardAssets.find((asset) => asset.name === field.name);
        filterdAssets.push(standardAsset);
    });
    return (_jsxs(Box, Object.assign({ "data-testid": 'sidebar-space-asset-form' }, { children: [_jsx(Accordion, Object.assign({ allowToggle: true }, { children: standardAssetTemplates.map((assetClassTemplate, i) => {
                    const assetClass = assetClassTemplate.assetClass;
                    const fieldIndexes = [];
                    fields.forEach((field, index) => {
                        if (field.assetClass === assetClass) {
                            fieldIndexes.push(index);
                        }
                    });
                    return (_jsxs(AccordionItem, Object.assign({ "data-testid": `${assetClass}-accordion` }, { children: [_jsxs(AccordionButton, { children: [_jsx(Box, Object.assign({ flex: '1', textAlign: 'left' }, { children: assetClass })), _jsx(AccordionIcon, {})] }), _jsx(AccordionPanel, Object.assign({ pb: 4 }, { children: _jsx(StandardAssetsDetails, { standardAssets: filterdAssets, spaceIdentity: spaceIdentity, spaceName: spaceName, assetTemplate: assetClassTemplate, assetTemplates: standardAssetTemplates, indexes: fieldIndexes, canEdit: canEdit, isEditing: isEditing, orgIdentity: orgIdentity }) }))] }), `${assetClassTemplate.assetClass}_${i}`));
                }) })), _jsx(Controller, { name: 'facilityAssignedAssets', control: control, render: ({ field: { value, onChange } }) => {
                    return (_jsx(FacilityAssetSelector, { allFacilityAssetsList: allFacilityAssetsList, facilityAssets: value, onChange: (assetIdentity) => {
                            const updatedAssets = value.includes(assetIdentity)
                                ? value.filter((identity) => identity !== assetIdentity)
                                : [...value, assetIdentity];
                            onChange(updatedAssets);
                            if (canEdit && !isEditing) {
                                setPageMode(PageModes.EDIT);
                            }
                        } }));
                } })] })));
};
