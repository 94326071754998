import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { PageModes } from '@/objects/FCAInterfaces';
import { Button, HStack, VStack } from '@chakra-ui/react';
import { CanvasMode } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
import { variants } from '@frontend/design-system/theme/theme';
import { useFormContext, useWatch } from 'react-hook-form';
import { VscAdd } from 'react-icons/vsc';
import { useAssetContext } from '../../../../../contexts/Assets/AssetContext';
import { PhotoProvider } from '../../../../../contexts/Photo/PhotoProvider';
import { useTemplateContext } from '../../../../../contexts/Template/TemplateContext';
import { createQuestionsForAssetForm } from '../../../../../conversions/AssetConversions';
import { DynamicFormInput, } from '../../Inputs/DynamicFormInput';
import { AddPhotoBtn } from '../AddPhotoModal/AddPhotoBtn';
import { PhotoType } from '../AddPhotoModal/SetUpPhotoModal';
import { PinnedAssetPhotos } from '../PhotoGalleryModal/PinnedAssetPhotos';
import { ConditionType } from '../Timeseries/ConditionChart/ConditionChart';
import { ChartType } from '../Timeseries/TimeseriesButtons';
import { installYearConstraint, validateYearRange, } from '../utility/Validations';
import { AssetForm } from './AssetForm';
export const AssetDetails = ({ orgId, facilityId, spaceId, asset, canEdit, }) => {
    var _a, _b;
    const { assetTemplates, getAssetTemplateByClass } = useTemplateContext();
    const { pageMode, setCanvasMode, currentSpaces } = useFacilityContext();
    const { reassessPinnedAssetCondition } = useAssetContext();
    const { control, getValues, setValue } = useFormContext();
    const addingOrUpdating = pageMode === PageModes.ADD_ASSET || pageMode === PageModes.EDIT
        ? true
        : false;
    const isViewMode = pageMode === PageModes.VIEW;
    const currentAssetClass = useWatch({
        name: 'assetClass',
        control: control,
    });
    const spaceName = (_a = currentSpaces.find((space) => space.identity === spaceId)) === null || _a === void 0 ? void 0 : _a.name;
    const conditions = asset && asset.conditions
        ? asset.conditions.map((d) => {
            return d.condition;
        })
        : [];
    const ageAdjustedConditions = asset && asset.conditions
        ? asset.conditions.map((d) => {
            return d.ageAdjustedCondition;
        })
        : [];
    const years = asset && asset.conditions
        ? asset.conditions.map((d) => {
            return new Date(d.assessedOn);
        })
        : [];
    const currentAssetTemplate = getAssetTemplateByClass(currentAssetClass);
    const currentAssetQuestions = useWatch({
        name: 'questions',
        control: control,
    });
    const handleAssetTypeChange = (selectedAssetType) => {
        const newQuestions = createQuestionsForAssetForm(getValues('questions'), currentAssetTemplate, selectedAssetType);
        setValue('questions', newQuestions);
    };
    const arrayOfStaticFormInputs = [
        {
            name: 'assetClass',
            inputType: 'dropdown',
            label: 'Asset Class',
            isDisabled: pageMode === PageModes.EDIT,
            choices: assetTemplates === null || assetTemplates === void 0 ? void 0 : assetTemplates.flatMap((template) => {
                return template.assetClass;
            }),
            required: true,
        },
        {
            name: 'name',
            inputType: 'text',
            label: 'Name',
        },
        {
            name: 'approximateInstallYear',
            inputType: 'number',
            label: 'Approx. Year of Install',
            validation: (year) => {
                const min = 1700;
                const max = new Date().getFullYear() + 1;
                return validateYearRange(year, min, max);
            },
            constraints: installYearConstraint,
            errorMessage: 'Please enter a year between now and 200 years in the past',
        },
        {
            name: 'modelNumber',
            inputType: 'text',
            label: 'Model Number',
        },
        {
            name: 'serialNumber',
            inputType: 'text',
            label: 'Serial Number',
        },
        {
            name: 'assetCondition',
            inputType: 'condition',
            label: 'Asset Condition',
            timeseriesConfig: {
                reassess: {
                    modalLabel: 'Condition',
                    isReassessable: ((_b = asset === null || asset === void 0 ? void 0 : asset.condition) !== null && _b !== void 0 ? _b : 0) > 0,
                    reassessCall: reassessPinnedAssetCondition,
                },
                graphConfig: {
                    chartData: {
                        data: {
                            datasets: [
                                {
                                    type: ConditionType.Observed,
                                    values: conditions,
                                    years: years,
                                },
                                {
                                    type: ConditionType.AgeAdjusted,
                                    values: ageAdjustedConditions,
                                    years: years,
                                },
                            ],
                            endOfLife: asset === null || asset === void 0 ? void 0 : asset.expectedEndOfLifeYear,
                        },
                        title: `${spaceName} / ${asset === null || asset === void 0 ? void 0 : asset.name}`,
                    },
                    type: ChartType.single,
                },
            },
        },
        {
            name: 'ageAdjustedCondition',
            inputType: 'condition',
            label: 'Age Adjusted Asset Condition',
            isHidden: addingOrUpdating ? true : false,
        },
        {
            name: 'assetType',
            inputType: 'dropdown',
            label: 'Asset Type',
            choices: (currentAssetTemplate === null || currentAssetTemplate === void 0 ? void 0 : currentAssetTemplate.assetTypes) || [],
            required: true,
            onChange: (e) => handleAssetTypeChange(e),
        },
    ];
    const isFacilityWideAsset = {
        name: 'isFacilityWide',
        inputType: 'checkbox',
        label: 'Facility Wide Asset',
    };
    const renderInputForm = () => {
        return (_jsx(VStack, Object.assign({ w: '100%' }, { children: _jsxs(VStack, Object.assign({ width: '100%', marginBottom: '1rem' }, { children: [_jsx(AssetForm, { formInputs: arrayOfStaticFormInputs, canEdit: canEdit, isEditing: addingOrUpdating, assetTemplate: currentAssetTemplate, assetQuestions: currentAssetQuestions || [] }), currentAssetTemplate ? (_jsx(HStack, Object.assign({ justifyContent: 'space-between', w: '100%' }, { children: (currentAssetTemplate === null || currentAssetTemplate === void 0 ? void 0 : currentAssetTemplate.classType) === 'Facility' && (_jsx(DynamicFormInput, { inputData: isFacilityWideAsset, control: control, isEditing: addingOrUpdating })) }))) : (_jsx(_Fragment, {}))] })) })));
    };
    const renderPhotoProvider = (asset) => {
        return (_jsxs(PhotoProvider, Object.assign({ orgIdentity: orgId, facilityIdentity: facilityId, defaultFilters: {
                assetIdentities: [
                    {
                        value: asset.identity,
                    },
                ],
            } }, { children: [_jsx(AddPhotoBtn, { photoType: PhotoType.PINNED_ASSET, canEdit: canEdit, spaceIdentity: spaceId, assetIdentity: asset.identity, assetName: asset.name }), _jsx(PinnedAssetPhotos, { spaceId: spaceId, asset: asset, canEdit: canEdit, orgIdentity: orgId })] })));
    };
    const renderAssetPinButton = () => {
        const buttonText = pageMode === PageModes.ADD_ASSET ||
            ((pageMode === PageModes.EDIT || pageMode === PageModes.VIEW) &&
                !(asset === null || asset === void 0 ? void 0 : asset.pin))
            ? 'Add pin'
            : 'Move pin';
        return (_jsx(Button, Object.assign({ onClick: () => setCanvasMode(CanvasMode.DRAW_PIN), onTouchEnd: () => setCanvasMode(CanvasMode.DRAW_PIN), rightIcon: _jsx(VscAdd, {}), fontSize: '14px', m: '0px', variant: variants.transparentBtn }, { children: buttonText })));
    };
    return (_jsx(VStack, Object.assign({ px: '31px', h: '100%', w: '100%', boxSizing: 'border-box' }, { children: _jsxs(_Fragment, { children: [renderInputForm(), canEdit && renderAssetPinButton(), asset && isViewMode && canEdit && renderPhotoProvider(asset)] }) })));
};
