import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Heading, HStack } from '@chakra-ui/react';
import { Dropdown } from '@frontend/design-system/components/Toolbar/Dropdown';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { appColors } from '../../../config/constants';
//TODO: remove duplication in all PageHeaders
/** Replaces old org base path with new org  */
const setNewOrgInPath = (path, newOrgIdentity, currentOrgIdentity) => {
    if (currentOrgIdentity) {
        return path.replace(AppPaths.organization.withOrgId(currentOrgIdentity), AppPaths.organization.withOrgId(newOrgIdentity));
    }
    else {
        return path;
    }
};
export const PageHeader = ({ title, selectedOrganizationIdentity, organizations, isLoading, onSelectOrganization, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleOrgChange = (orgIdentity) => {
        onSelectOrganization(orgIdentity);
        const url = setNewOrgInPath(location.pathname, orgIdentity, selectedOrganizationIdentity);
        navigate(url);
    };
    return (_jsx(_Fragment, { children: _jsx(Flex, Object.assign({ as: 'header', minH: '50px', w: '100%', alignItems: 'center' }, { children: _jsxs(HStack, Object.assign({ borderBottom: '1px solid #E6E7E9', justifyContent: 'space-between', w: '100%', h: '100%', p: '5px 40px' }, { children: [_jsx(Heading, Object.assign({ as: 'h1', fontSize: 36, fontWeight: 400, color: appColors.TEXT_SEC_DARK_GRAY, whiteSpace: 'nowrap' }, { children: title })), _jsx(Dropdown, { dropdownValues: organizations.map((org) => ({
                            label: org.name,
                            value: org.identity,
                        })) || [], label: 'Organization', isLoading: isLoading, handleChange: handleOrgChange, value: selectedOrganizationIdentity || '', bgColor: appColors.PRIM_BLUE })] })) })) }));
};
