import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
import { MdWarning } from 'react-icons/md';
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
export const WarningSpaceDefinition = ({ disclosure: { isOpen, onClose }, onCancel, onContinue, }) => {
    const handleCancel = () => {
        onCancel();
        onClose();
    };
    const handleContinue = () => {
        onContinue();
        onClose();
    };
    return (_jsxs(Modal, Object.assign({ closeOnEsc: false, closeOnOverlayClick: false, isOpen: isOpen, isCentered: true, onClose: onClose, size: 'xl', "data-testid": 'space-definition-warning' }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ bg: slgqColors.primWhite, padding: '40px' }, { children: [_jsx(ModalHeader, Object.assign({ padding: '0px' }, { children: _jsxs(HStack, { children: [_jsx(MdWarning, { color: '#ECAF00', size: '23px' }), _jsx(Text, Object.assign({ fontSize: '18px', fontWeight: 400 }, { children: "Changing Space Definition" }))] }) })), _jsxs(ModalBody, Object.assign({ p: '0px', mt: '40px', mb: '40px', fontSize: '14px' }, { children: [_jsx(Text, Object.assign({ mb: '20px' }, { children: "You are about to change the space definition for an entire space. This action will remove the data for space type." })), _jsx(Text, { children: "Are you sure you want to continue?" })] })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(Flex, Object.assign({ w: '100%', justifyContent: 'space-between' }, { children: [_jsx(Button, Object.assign({ fontSize: '14px', fontWeight: '500px', onClick: handleCancel, type: 'button', variant: variants.whiteBtn, padding: '8px 20px', w: 'auto' }, { children: "Cancel" })), _jsx(Button, Object.assign({ bg: '#DA1100', borderColor: '#DA1100', color: slgqColors.primWhite, fontSize: '14px', fontWeight: '500px', onClick: handleContinue, type: 'button', variant: variants.whiteBtn, padding: '8px 20px', w: 'auto' }, { children: "Continue" }))] })) }))] }))] })));
};
