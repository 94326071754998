import { SpaceDefinitionEnum } from '@frontend/clients/mysiteiq/models/SpaceDefinitionEnum';
import { AssetClassType, SpaceCategoryEnum, } from '../objects/FCAInterfaces';
export const makeOldAsset = (overrides) => {
    return Object.assign({ id: 'abc', assetIdentity: 11, spaceIdentity: 2, indicator: AssetClassType.STANDARD, assetType: 'Flooring', name: 'Flooring 1', approxYear: 1997, modelNum: '', serialNum: '', assetCondition: 1, ageAdjustedCondition: 3, category: 'Interior', isFacilityWide: false, questions: [
            {
                id: '123',
                surveyName: 'Flooring',
                entityType: 'space',
                inputType: 'string',
                question: 'Flooring Type',
                stringAnsw: 'Epoxy',
            },
            {
                id: '123',
                surveyName: 'Flooring',
                entityType: 'space',
                inputType: 'number',
                question: 'Flooring %',
                numberAnsw: 100,
            },
        ], isHidden: false }, overrides);
};
export const makeAssetFormInterface = (assetFormPartial) => {
    return Object.assign({ name: 'Tiled Ceiling', assetClass: 'Ceiling', assetType: 'Vinyl Tiling', assetCondition: '1', approximateInstallYear: '2020', modelNumber: '1001', serialNumber: '110110', location: {
            x: 100,
            y: 100,
        }, notes: 'Ceiling has no notable defects or issues', isFacilityWide: undefined, questions: [] }, assetFormPartial);
};
export const makeAsset = (assetFormPartial) => {
    return Object.assign({ identity: 1, spaceIdentity: 1, name: 'Tiled Ceiling', assetCategory: 'Interior', assetClass: 'Ceiling', assetClassType: '', assetType: 'Vinyl Tiling', condition: 1, ageAdjustedCondition: 1, modelNumber: '1001', serialNumber: '110110', pin: {
            x: 100,
            y: 100,
        }, note: 'Ceiling has no notable defects or issues', isFacilityWide: undefined, questions: [] }, assetFormPartial);
};
export const makeFacilityAssignedAsset = (overrides) => {
    return Object.assign({ isAssigned: false, name: 'Test Asset 1', identity: 1 }, overrides);
};
export const makeClientAsset = (assetFormPartial) => {
    return Object.assign({ id: '', assetIdentity: 1, spaceIdentity: 1, facilityIdentity: 1, indicator: 'Facility', approxYear: 2020, name: 'Tiled Ceiling', category: 'Interior', assetType: 'Vinyl Tiling', assetCondition: 1, ageAdjustedCondition: 1, modelNum: '1001', serialNum: '110110', pin: {
            assetIdentity: 1,
            x: 100,
            y: 100,
            isDeleted: false,
        }, note: 'Ceiling has no notable defects or issues', isFacilityWide: false, photos: [], questions: [], isHidden: false, isDeleted: false }, assetFormPartial);
};
export const makePhoto = (overrides) => {
    return Object.assign({ id: '123', photoIdentity: 1, name: 'Photo.jpg', url: 'http', favorite: false, comments: [], createDate: new Date(), date: new Date(), tags: [] }, overrides);
};
export const makeSpace = (overrides) => {
    return Object.assign({ id: '123', spaceIdentity: 1, facilityIdentity: 2, spaceId: 'My Space', constrYear: 1997, spaceArea: 420, definition: SpaceCategoryEnum.FACILITY, description: 'Some Description', note: '', isAssessed: true, type: 'Art Room', roomFunction: 'Cafeteria', occupied: true, numberOfOccupants: 5, shape: [], light: {
            value: 15,
            rating: 'Green',
            allValues: [],
        }, sound: {
            value: 15,
            rating: 'Green',
            allValues: [],
        }, co2: {
            value: 20,
            rating: 'Blue',
            allValues: [],
        }, temperature: {
            value: 70,
            rating: 'Red',
            allValues: [],
        }, humidity: {
            value: 14,
            rating: 'Green',
            allValues: [],
        }, facilityAssets: [] }, overrides);
};
export const makeClientSpace = (overrides) => {
    return Object.assign({ id: '123', spaceIdentity: 7, facilityIdentity: 2, spaceId: 'My Space 1', constrYear: 1997, spaceArea: 420, type: 'Art Room', definition: SpaceDefinitionEnum.FACILITY, description: 'Some Description', isAssessed: true, x: 1, y: 2, width: 21, length: 20, angle: 60, opacity: 12, light: 15, sound: 15, co2: 20, temperature: 70, humidity: 14, roomFunction: 'Cafeteria', occupied: true, numberOfOccupants: 5, ieqDate: '', shape: [], note: '', photos: [], assets: [], facilityAssets: [], isHidden: false, isDeleted: false }, overrides);
};
export const makeSpaceDetailResponse = (overrides) => {
    return Object.assign({ spaceId: 1, spaceCategory: 'Facility', spaceType: 'Classroom', function: 'Band room', spaceName: 'Test space', constructionYear: 1980, isAssessed: false, points: [], photos: [], servedBy: [] }, overrides);
};
export const makeSpaceSummary = (overrides) => {
    return Object.assign({ name: 'My Space', identity: 1, category: SpaceCategoryEnum.FACILITY, isAssessed: true, location: [], assets: [] }, overrides);
};
export const makeSpaceForm = (spaceFormPartial) => {
    return Object.assign({ name: 'Test Space', definition: SpaceCategoryEnum.FACILITY, type: 'Other', constructionYear: '2001', isAssessed: false, roomFunction: 'Other', standardAssets: [], facilityAssignedAssets: [] }, spaceFormPartial);
};
export const makeSpaceMetricResponse = (overrides) => {
    return Object.assign({ cO2MetricHistory: [
            {
                value: 12,
                measuredOn: '20230307',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
            {
                value: 6,
                measuredOn: '20230301',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
        ], lightMetricHistory: [
            {
                value: 12,
                measuredOn: '20230307',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
            {
                value: 6,
                measuredOn: '20230301',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
        ], occupancyMetricHistory: [
            {
                value: 12,
                measuredOn: '20230307',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
            {
                value: 6,
                measuredOn: '20230301',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
        ], soundMetricHistory: [
            {
                value: 12,
                measuredOn: '20230307',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
            {
                value: 6,
                measuredOn: '20230301',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
        ], temperatureMetricHistory: [
            {
                value: 12,
                measuredOn: '20230301',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
            {
                value: 6,
                measuredOn: '20240307',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20240307',
            },
        ], humidityMetricHistory: [
            {
                value: 12,
                measuredOn: '20230307',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
            {
                value: 6,
                measuredOn: '20230301',
                auditorFirstName: 'Emilio',
                auditorLastName: 'Alposta',
                auditedOn: '20230307',
            },
        ] }, overrides);
};
export const makeCurrentUniqueAsset = (overrides) => {
    return Object.assign({ identity: 1, name: 'Asset 1', location: {
            x: 456,
            y: 555,
        } }, overrides);
};
export const makePlan = (overrides) => {
    return Object.assign({ id: 'abc123', identity: 1, name: 'testPlan', planFileName: undefined }, overrides);
};
export const makeClientPlan = (overrides) => {
    return Object.assign({ id: 'abc123', identity: 1, name: 'testPlan', planFileName: 'planFileName', spaces: [], isDeleted: false }, overrides);
};
export const mockFetchClientSpacesPlanData = makeClientPlan({
    spaces: [
        makeClientSpace({
            spaceIdentity: 1,
            definition: SpaceDefinitionEnum.GROUND,
        }),
        makeClientSpace({
            spaceIdentity: 2,
            spaceId: 'My Space 2',
            assets: [
                makeClientAsset({
                    assetIdentity: 1,
                    spaceIdentity: 2,
                    indicator: AssetClassType.PINNED,
                    assetType: 'Electrical',
                    name: 'Asset 1',
                    category: 'Electrical',
                    questions: [],
                }),
                makeClientAsset({
                    assetIdentity: 2,
                    spaceIdentity: 2,
                    indicator: AssetClassType.PINNED,
                    assetType: 'Electrical',
                    name: 'Asset 2',
                    category: 'Electrical',
                    questions: [],
                }),
            ],
        }),
        makeClientSpace({
            spaceIdentity: 3,
            spaceId: 'My Space 3',
        }),
        makeClientSpace({
            spaceIdentity: 4,
            spaceId: 'My Space 4',
        }),
        makeClientSpace({
            spaceIdentity: 5,
            spaceId: 'My Space 5',
        }),
    ],
});
export const makeSpaceTemplate = (overrides) => {
    return Object.assign({ spaceType: 'Classroom', functions: ['Indoor Classroom', 'Outdoor Classroom'] }, overrides);
};
export const makeAssetTemplate = (overrides) => {
    return Object.assign({ assetClass: 'assetTemplate', classType: AssetClassType.PINNED, assetCategory: 'Electrical', version: 1, survey: [
            {
                name: 'quantity',
                label: 'Quantity',
                inputType: 'number',
                valueType: 'string',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
            },
            {
                name: 'Special Field',
                label: 'Special Field',
                inputType: 'number',
                valueType: 'string',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Type2'],
                    },
                ],
            },
        ], assetTypes: ['Type1', 'Type2'] }, overrides);
};
export const makeAssetClassQuestion = (overrides) => {
    return Object.assign({ inputType: 'number', valueType: 'string', isRequired: false }, overrides);
};
export const makeAssetDetailResponse = (overrides) => {
    return Object.assign({ assetId: 1, assetName: 'Casework', assetClassType: AssetClassType.STANDARD, assetCategory: 'Interior', assetClass: 'Casework', assetType: 'Metal', conditions: [], photos: [], isFacilityAsset: false, spaceId: 1 }, overrides);
};
export const makeAssetSurveyResponse = (overrides) => {
    return Object.assign({ versionId: 1, answers: [] }, overrides);
};
export const makeAssetConditionResponse = (overrides) => {
    return Object.assign({ assessedOn: new Date().toDateString(), auditorFirstName: 'Bilbo', auditorLastName: 'Baggins', auditedOn: new Date().toDateString() }, overrides);
};
export const testFacilityAssetTemplate = [
    {
        assetClass: 'Flooring',
        classType: 'Standard',
        assetCategory: 'Interior',
        version: 1,
        survey: [
            {
                name: 'AreaPercentage',
                label: 'Flooring %',
                inputType: 'number',
                valueType: 'number',
                isRequired: true,
                minimumValue: 0,
                maximumValue: 100,
                choices: null,
            },
            {
                name: 'Separation',
                label: 'Separation at seams?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Carpet-Rolled', 'Carpet-Tile'],
                    },
                ],
            },
            {
                name: 'RipsTearsStains',
                label: 'Rips/Tears/Stains?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Carpet-Rolled', 'Carpet-Tile'],
                    },
                ],
            },
            {
                name: 'WearDiscolorationDamage',
                label: 'Wear/Discoloration/Damage?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: [
                            'Ceramic Tile',
                            'Concrete',
                            'Epoxy',
                            'Laminate',
                            'Linoleum',
                            'Luxury Vinyl Tile',
                            'Other Hard Surface',
                            'Rubber',
                            'Sheet Vinyl',
                            'Terrazzo',
                            'Terrazzo Tile',
                            'Vinyl Asbestos Tile',
                            'Vinyl Composition Tile',
                            'Wood',
                            'Other',
                        ],
                    },
                ],
            },
            {
                name: 'DamagedGrout',
                label: 'Damaged Grout?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Ceramic Tile'],
                    },
                ],
            },
            {
                name: 'SpallingDeterioration',
                label: 'Spalling/Deterioration?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Concrete'],
                    },
                ],
            },
            {
                name: 'RipplingBuckling',
                label: 'Rippling/Buckling?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Carpet-Rolled'],
                    },
                ],
            },
            {
                name: 'Cracking',
                label: 'Cracking?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Terrazzo', 'Terrazzo Tile'],
                    },
                ],
            },
        ],
        assetTypes: [
            'Carpet-Rolled',
            'Carpet-Tile',
            'Ceramic Tile',
            'Concrete',
            'Epoxy',
            'Laminate',
            'Linoleum',
            'Luxury Vinyl Tile',
            'Other',
            'Other Hard Surface',
            'Rubber',
            'Sheet Vinyl',
            'Terrazzo',
            'Terrazzo Tile',
            'Vinyl Asbestos Tile',
            'Vinyl Composition Tile',
            'Wood',
        ],
    },
    {
        assetClass: 'Ceiling',
        classType: 'Standard',
        assetCategory: 'Interior',
        version: 1,
        survey: [
            {
                name: 'DamagedTiles',
                label: 'Damaged/Discolored/Warped Tiles/Water Stains?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['2x2 Tiles', '2x4 Tiles'],
                    },
                ],
            },
            {
                name: 'DamagedPeelingPaint',
                label: 'Damaged/Peeling Paint?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Exposed Roof Deck', 'Open Ceiling'],
                    },
                ],
            },
            {
                name: 'DamagedDiscoloredWaterStains',
                label: 'Damaged/Discolored/Water Stains?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: [
                            'Hard Lid',
                            'Splined 12" Tiles',
                            'Adhered 12" Tiles',
                            'Architectural',
                            'Wood',
                            'Other',
                        ],
                    },
                ],
            },
            {
                name: 'DamagedSpalling',
                label: 'Damaged/Spalling?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['Concrete'],
                    },
                ],
            },
            {
                name: 'DamagedDiscoloredGrid',
                label: 'Damaged/Discolored Grid',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: ['2x2 Tiles', '2x4 Tiles'],
                    },
                ],
            },
            {
                name: 'CeilingHeightFromFloor',
                label: 'Ceiling Height From Floor',
                inputType: 'qty linear ft',
                valueType: 'linearft',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
            },
            {
                name: 'CeilingToRoofDeck',
                label: 'Ceiling to roof deck measurement',
                inputType: 'qty linear ft',
                valueType: 'linearft',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: [
                            'Splined 12" Tiles',
                            'Adhered 12" Tiles',
                            'Wood',
                            'Other',
                        ],
                    },
                ],
            },
            {
                name: 'DistanceBetweenJoists',
                label: 'Distance Between Joists',
                inputType: 'qty linear ft',
                valueType: 'linearft',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
            },
            {
                name: 'CeilingToBottomOfJoistMeasurement',
                label: 'Ceiling to bottom of joist measurement',
                inputType: 'qty linear ft',
                valueType: 'linearft',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
                triggeredBy: [
                    {
                        questionName: 'AssetType',
                        values: [
                            '2x2 Tiles',
                            '2x4 Tiles',
                            'Splined 12" Tiles',
                            'Adhered 12" Tiles',
                            'Architectural',
                            'Wood',
                            'Other',
                        ],
                    },
                ],
            },
            {
                name: 'JoistDirection',
                label: 'Joist Direction',
                inputType: 'dropdown',
                valueType: 'string',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: ['Parallel to Hallway', 'Perpendicular to Hallway'],
            },
        ],
        assetTypes: [
            '2x2 Tiles',
            '2x4 Tiles',
            'Adhered 12" Tiles',
            'Architectural',
            'Concrete',
            'Exposed Roof Deck',
            'Hard Lid',
            'Open Ceiling',
            'Other',
            'Splined 12" Tiles',
            'Wood',
        ],
    },
    {
        assetClass: 'Lighting',
        classType: 'Standard',
        assetCategory: 'Electrical',
        version: 1,
        survey: [
            {
                name: 'FixtureSize',
                label: 'Fixture Size',
                inputType: 'dropdown',
                valueType: 'string',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: [
                    '2x4 trougher',
                    '2x2 trougher',
                    '4 Strip',
                    '8 Strip',
                    'Other',
                ],
            },
            {
                name: 'FixtureCount',
                label: 'Fixture Count',
                inputType: 'number',
                valueType: 'number',
                isRequired: false,
                minimumValue: 0,
                maximumValue: 500,
                choices: null,
            },
            {
                name: 'LightingControl',
                label: 'Lighting Control',
                inputType: 'dropdown',
                valueType: 'string',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: [
                    'On/Off Switch',
                    'Inboard/Outboard Switching',
                    'Bi-level Switching',
                    'Dimming',
                ],
            },
            {
                name: 'OccupancySensor',
                label: 'Occupancy Sensor?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
            },
            {
                name: 'DaylightingControl',
                label: 'Daylighting Control/Photocell?',
                inputType: 'checkbox',
                valueType: 'boolean',
                isRequired: false,
                minimumValue: null,
                maximumValue: null,
                choices: null,
            },
        ],
        assetTypes: [
            'CFL',
            'Fluorescent',
            'Halogen',
            'High Pressure Sodium',
            'Incandescent',
            'LED',
            'Metal Halide',
            'Other',
        ],
    },
];
