import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThumbnailItem } from '@/pages/Main/Sidebar/DetailsSidebar/PhotoGalleryModal/ThumbnailItem';
import { Box, useDisclosure } from '@chakra-ui/react';
import { usePhotoSelector } from '../../../contexts/Photo/PhotoSelectContext';
import { ExpandedPhotoModal } from './ExpandedPhotoModal';
export const GalleryGridItem = ({ photo, index }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isSelecting, isPhotoSelected, selectPhoto, unselectPhoto, selectLimitReached, } = usePhotoSelector();
    const photoSelected = isPhotoSelected(photo);
    const onGallerySelect = () => {
        if (isPhotoSelected(photo)) {
            unselectPhoto(photo);
        }
        else {
            selectPhoto(photo);
        }
    };
    return (_jsxs(Box, Object.assign({ height: '177px' }, { children: [_jsx(ThumbnailItem, { photo: photo, maxH: 'inherit', borderRadius: '5px', handleClick: onOpen, isSelectMode: isSelecting, isSelected: photoSelected, onSelect: onGallerySelect, isSelectDisabled: !photoSelected && selectLimitReached() }, index + photo.name), _jsx(ExpandedPhotoModal, { isExpandedModalOpen: isOpen, onCloseExpandedModal: onClose })] }), `photo_${index}`));
};
