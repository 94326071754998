import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import facilityReducer from './facilitySlice';
import photoReducer from './photoSlice';
import serviceWorkerReducer from './serviceWorkerSlice';
export const history = createBrowserHistory();
const rootReducer = combineReducers({
    facility: facilityReducer,
    photo: photoReducer,
    serviceWorker: serviceWorkerReducer,
    router: connectRouter(history),
});
export const store = setupStore();
export function setupStore(preloadedState) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
}
setupListeners(store.dispatch);
