import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack } from '@chakra-ui/react';
import { ConditionChartButton } from './ConditionChart/ConditionChartButton';
import { ReassessButton } from './Reassess/ReassessButton';
export var ChartType;
(function (ChartType) {
    ChartType[ChartType["single"] = 0] = "single";
    ChartType[ChartType["multiple"] = 1] = "multiple";
})(ChartType || (ChartType = {}));
export const TimeseriesButtons = ({ children, canEdit, timeseriesConfig, name, }) => {
    var _a, _b, _c, _d;
    const dataset = (_c = (_b = (_a = timeseriesConfig === null || timeseriesConfig === void 0 ? void 0 : timeseriesConfig.graphConfig) === null || _a === void 0 ? void 0 : _a.chartData) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.datasets.find((dataset) => { var _a, _b; return dataset.type === ((_b = (_a = timeseriesConfig === null || timeseriesConfig === void 0 ? void 0 : timeseriesConfig.graphConfig) === null || _a === void 0 ? void 0 : _a.chartData) === null || _b === void 0 ? void 0 : _b.title); });
    const valuesLength = ((_d = dataset === null || dataset === void 0 ? void 0 : dataset.values) === null || _d === void 0 ? void 0 : _d.length) || 0;
    const isGreaterThanOne = valuesLength > 1;
    return (_jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: '100%' }, { children: [_jsx(Box, Object.assign({ style: { flex: 1 } }, { children: children })), _jsxs(HStack, Object.assign({ display: 'flex', alignItems: 'center' }, { children: [((timeseriesConfig &&
                        timeseriesConfig.graphConfig.type === ChartType.single &&
                        timeseriesConfig.graphConfig.chartData.data.datasets &&
                        timeseriesConfig.graphConfig.chartData.data.datasets[0] &&
                        timeseriesConfig.graphConfig.chartData.data.datasets[0]
                            .values.length > 1) ||
                        (timeseriesConfig &&
                            timeseriesConfig.graphConfig.type ===
                                ChartType.multiple &&
                            timeseriesConfig.graphConfig.chartData.data.datasets &&
                            timeseriesConfig.graphConfig.chartData.data.datasets
                                .length > 0 &&
                            isGreaterThanOne)) && (_jsx(ConditionChartButton, { "data-testid": 'chart-button', graphConfig: timeseriesConfig.graphConfig })), timeseriesConfig &&
                        timeseriesConfig.reassess &&
                        timeseriesConfig.reassess.isReassessable &&
                        canEdit && (_jsx(ReassessButton, { children: children, reassessConfig: timeseriesConfig.reassess, type: name }))] }))] })));
};
