import axios from 'axios';
import { environment } from '../config/environment';
import FacilityService from './facilityService';
import PhotoService from './photoService';
import PlanService from './planService';
import RestService from './RestService';
import TagService from './tagService';
export const apiService = new RestService(axios, environment.apiBase);
export const platformApiService = new RestService(axios, environment.platformApi);
const facilityService = new FacilityService(apiService);
const planService = new PlanService(apiService);
const photoService = new PhotoService(apiService, platformApiService);
const tagService = new TagService(apiService);
const services = {
    facilityService,
    planService,
    photoService,
    tagService,
};
export default services;
