import { createSlice } from '@reduxjs/toolkit';
export const initialPhotoState = {
    filteredPhotos: [],
};
export const PhotoSlice = createSlice({
    name: 'Photo',
    initialState: initialPhotoState,
    reducers: {
        setFilteredPhotos: (state, { payload: filteredPhotos }) => {
            state.filteredPhotos = filteredPhotos;
        },
    },
});
export const { setFilteredPhotos } = PhotoSlice.actions;
export default PhotoSlice.reducer;
