import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckboxInput } from '@frontend/design-system/components/DynamicInput/CheckboxInput';
import { NumberDynamicInput } from '@frontend/design-system/components/DynamicInput/NumberDynamicInput';
import { RadioGroupInput } from '@frontend/design-system/components/DynamicInput/RadioGroupInput';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { TextAreaInput } from '@frontend/design-system/components/DynamicInput/TextAreaInput';
import { TextInput } from '@frontend/design-system/components/DynamicInput/TextInput';
import React from 'react';
import { stringToInt } from '../../../../conversions/AssetConversions';
import { QtyLinearFtDynamic } from '../DetailsSidebar/Asset/QtyLinearFtDynamic';
import { ConditionSelectInput } from '../DetailsSidebar/ConditionSelectInput';
import { variants } from '@frontend/design-system/theme/theme';
export const DynamicInput = ({ data, editMode, inputType, label, errorMessage, choices, isDisabled = false, handleInputChange, required, constraints, color, infoPopover, placeholder, }) => {
    const renderInput = () => {
        var _a;
        switch (inputType) {
            case 'dropdown':
                if (choices) {
                    return (_jsx(SelectInput, { label: label, editable: editMode, data: typeof data === 'string' ? data : undefined, choices: (_a = choices === null || choices === void 0 ? void 0 : choices.map((choice) => ({
                            label: choice,
                            value: choice,
                        }))) !== null && _a !== void 0 ? _a : [], onInputChange: handleInputChange, required: required, isDisabled: isDisabled, placeholder: placeholder, variant: variants.fcaBaseStyle }));
                }
                else {
                    return _jsx(_Fragment, {});
                }
            case 'qty linear ft':
                return (_jsx(QtyLinearFtDynamic, { editable: editMode, label: label, answer: data, onInputChange: handleInputChange, required: required }));
            case 'number':
                return (_jsx(NumberDynamicInput, { label: label, editMode: editMode, data: data, errorMessage: errorMessage, onInputChange: handleInputChange, required: required, constraints: constraints, color: color, infoPopover: infoPopover }));
            case 'date':
            case 'text':
                return (_jsx(TextInput, { label: label, editMode: editMode, inputType: inputType, data: typeof data === 'number' ||
                        typeof data === 'string' ||
                        data instanceof Date
                        ? data
                        : '', errorMessage: errorMessage, onInputChange: handleInputChange, required: required, constraints: constraints, variant: variants.fcaBaseStyle }));
            case 'condition':
                return (_jsx(ConditionSelectInput, { label: label, editable: editMode, data: stringToInt(data), onInputChange: handleInputChange, required: required, isDisabled: isDisabled }));
            case 'checkbox':
                return (_jsx(CheckboxInput, { label: label, editMode: editMode, data: typeof data === 'boolean' ? data : undefined, onInputChange: handleInputChange, position: 'right', required: required, isDisabled: isDisabled, errorMessage: errorMessage, variant: variants.fcaBaseStyle }));
            case 'leftCheckbox':
                return (_jsx(CheckboxInput, { label: label, editMode: editMode, data: typeof data === 'boolean' ? data : undefined, onInputChange: handleInputChange, position: 'left', required: required, isDisabled: isDisabled, errorMessage: errorMessage, variant: variants.fcaBaseStyle }));
            case 'radio':
                return (_jsx(RadioGroupInput, { label: label, editMode: editMode, data: data, choices: choices || [], onInputChange: handleInputChange, required: required, isDisabled: isDisabled, errorMessage: errorMessage, variant: variants.fcaBaseStyle }));
            case 'textarea':
                return (_jsx(TextAreaInput, { label: label, editMode: editMode, data: data, onInputChange: handleInputChange, required: required, isDisabled: isDisabled, errorMessage: errorMessage, placeholder: placeholder, variant: variants.fcaBaseStyle }));
            default:
                return _jsx(_Fragment, {});
        }
    };
    return _jsx(_Fragment, { children: renderInput() });
};
