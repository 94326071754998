import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, } from '@chakra-ui/react';
import { useRef } from 'react';
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
import { useFacilityContext } from '../../../../../contexts/Facilities/FacilityPageContext';
import { PageModes } from '../../../../../objects/FCAInterfaces';
export const DeleteAssetModal = ({ isOpen, onClose, }) => {
    const touchHandledRef = useRef(false);
    const { selectedSpaceIdentity, selectedAssetIdentity, currentSpaces, deleteAsset, setPageMode, } = useFacilityContext();
    const space = currentSpaces.find((space) => space.identity === selectedSpaceIdentity);
    const asset = space === null || space === void 0 ? void 0 : space.assets.find((asset) => asset.identity === selectedAssetIdentity);
    const handleDeleteAsset = (assetId, spaceId) => {
        deleteAsset(assetId, spaceId);
        setPageMode(PageModes.VIEW);
    };
    return (_jsxs(Modal, Object.assign({ closeOnEsc: false, closeOnOverlayClick: false, isOpen: isOpen, isCentered: true, onClose: onClose, size: 'xl' }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ padding: '40px' }, { children: [_jsx(ModalHeader, Object.assign({ color: slgqColors.secGrey, fontSize: '36px', fontWeight: 400, padding: '0px' }, { children: "Delete Asset" })), _jsx(ModalBody, Object.assign({ p: '0px', mt: '20px', mb: '40px' }, { children: _jsxs(Text, Object.assign({ fontSize: '20px' }, { children: ["You are about to remove the Asset, \"", asset === null || asset === void 0 ? void 0 : asset.name, "\". Are you sure you want to continue?"] })) })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(Flex, Object.assign({ w: '100%' }, { children: [_jsx(Button, Object.assign({ fontSize: '14px', onClick: () => onClose(), onTouchEnd: () => onClose(), type: 'button', variant: variants.whiteBtn }, { children: "CANCEL" })), _jsx(Spacer, {}), _jsx(Button, Object.assign({ bg: '#DA1100', borderColor: '#DA1100', color: slgqColors.primWhite, fontSize: '14px', onClick: () => {
                                        if (touchHandledRef.current) {
                                            // Prevent onClick if onTouchEnd has been handled
                                            touchHandledRef.current = false;
                                            return;
                                        }
                                        if (selectedAssetIdentity &&
                                            selectedSpaceIdentity) {
                                            handleDeleteAsset(selectedAssetIdentity, selectedSpaceIdentity);
                                        }
                                        onClose();
                                    }, onTouchEnd: () => {
                                        touchHandledRef.current = true;
                                        if (selectedAssetIdentity &&
                                            selectedSpaceIdentity) {
                                            handleDeleteAsset(selectedAssetIdentity, selectedSpaceIdentity);
                                        }
                                        onClose();
                                    }, type: 'button', variant: variants.whiteBtn }, { children: "DELETE" }))] })) }))] }))] })));
};
