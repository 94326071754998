var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { convertBlobToFile } from '../conversion/GeneralConversions';
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
export const useFacilityService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const exportCSVFiles = (facilityIdentity, fileName, type, organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.facility.postApiV1FacilityExport({
            facilityId: facilityIdentity,
            organizationId: organizationIdentity,
        }), {
            error: { label: 'Error occurred exporting csv files' },
            success: { enabled: false },
        })
            .then((res) => {
            return convertBlobToFile(res, fileName, type);
        })
            .catch(() => undefined);
    });
    return {
        facility: {
            exportCSVFiles,
        },
    };
};
