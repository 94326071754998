import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSpaceContext } from '@/contexts/Spaces/SpaceContext';
import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { createQuestionsForAssetForm } from '../../../../../../conversions/AssetConversions';
import { DynamicInput } from '../../../Inputs/DynamicInput';
import { AddPhotoBtn } from '../../AddPhotoModal/AddPhotoBtn';
import { PhotoType } from '../../AddPhotoModal/SetUpPhotoModal';
import { AssetForm } from '../../Asset/AssetForm';
import SpacePhotoCarousel from '../../PhotoGalleryModal/SpacePhotoCarousel';
import { ConditionType } from '../../Timeseries/ConditionChart/ConditionChart';
import { ChartType } from '../../Timeseries/TimeseriesButtons';
import { installYearConstraint, validateYearRange, } from '../../utility/Validations';
import { classHasMultipleAssets } from './StandardAssets';
export const StandardAssetsDetails = ({ spaceIdentity, spaceName, assetTemplate, assetTemplates, indexes, canEdit, isEditing, standardAssets, orgIdentity, }) => {
    const assetClass = assetTemplate.assetClass;
    const canHaveSelect = classHasMultipleAssets.includes(assetClass);
    const { reassessStandardAssetCondition } = useSpaceContext();
    const { control, getValues, setValue } = useFormContext();
    const nameOptions = [`${assetClass} 1`, `${assetClass} 2`];
    const [nameOption, setNameOption] = useState(nameOptions[0]);
    const SingleAssetDetails = ({ index, isFirstAssetOfClass, }) => {
        const currentAssetIdentity = useWatch({
            name: `standardAssets.${index}.identity`,
            control: control,
        });
        const currentAssetName = useWatch({
            name: `standardAssets.${index}.name`,
            control: control,
        });
        const currentQuestions = useWatch({
            name: `standardAssets.${index}.questions`,
            control: control,
        });
        return (_jsxs(_Fragment, { children: [_jsx(AssetForm, { path: `standardAssets.${index}`, formInputs: generateFormInputs(index), canEdit: canEdit, isEditing: isEditing, assetTemplate: assetTemplate, assetQuestions: currentQuestions || [], isFirstAssetOfClass: isFirstAssetOfClass }), !isEditing && (_jsxs(_Fragment, { children: [canEdit && currentAssetIdentity && (_jsx(AddPhotoBtn, { photoType: PhotoType.STANDARD_ASSET, canEdit: canEdit, spaceIdentity: spaceIdentity, spaceName: spaceName, assetIdentity: currentAssetIdentity, assetName: currentAssetName })), _jsx(SpacePhotoCarousel, { spaceIdentity: spaceIdentity, spaceName: spaceName, standardAssetClass: assetTemplate.assetClass, assetTemplates: assetTemplates, canEdit: canEdit, orgIdentity: orgIdentity })] }))] }));
    };
    const generateFormInputs = (index) => {
        const currentId = useWatch({
            name: `standardAssets.${index}.identity`,
            control: control,
        });
        const currentCondition = useWatch({
            name: `standardAssets.${index}.assetCondition`,
            control: control,
        });
        const getConditionDataset = (index) => {
            const asset = standardAssets &&
                standardAssets.length &&
                standardAssets[index];
            const conditionsTemp = asset && asset.conditions ? asset.conditions : undefined;
            const conditions = conditionsTemp && conditionsTemp.length > 0
                ? conditionsTemp.map((d) => {
                    return d.condition;
                })
                : [];
            return conditions;
        };
        const getAgeAdjustedConditionDataset = (index) => {
            const asset = standardAssets &&
                standardAssets.length &&
                standardAssets[index];
            const conditionsTemp = asset && asset.conditions ? asset.conditions : undefined;
            const conditions = conditionsTemp && conditionsTemp.length > 0
                ? conditionsTemp.map((d) => {
                    return d.ageAdjustedCondition;
                })
                : [];
            return conditions;
        };
        const getYears = (index) => {
            const asset = standardAssets &&
                standardAssets.length &&
                standardAssets[index];
            const conditionsTemp = asset && asset.conditions ? asset.conditions : undefined;
            const conditions = conditionsTemp && conditionsTemp.length > 0
                ? conditionsTemp.map((d) => {
                    return new Date(d.assessedOn);
                })
                : [];
            return conditions;
        };
        const getName = (index) => {
            const name = useWatch({
                name: `standardAssets.${index}.name`,
                control: control,
            });
            return `${spaceName} / ${name}`;
        };
        const handleAssetTypeChange = (selectedAssetType) => {
            const newQuestions = createQuestionsForAssetForm(getValues(`standardAssets.${index}.questions`), assetTemplate, selectedAssetType);
            setValue(`standardAssets.${index}.questions`, newQuestions);
        };
        const formInputs = [
            {
                name: `standardAssets.${index}.approximateInstallYear`,
                inputType: 'number',
                label: 'Approx. Year of Install',
                validation: (year) => {
                    const min = 1700;
                    const max = new Date().getFullYear() + 1;
                    return validateYearRange(year, min, max);
                },
                constraints: installYearConstraint,
                errorMessage: 'Please enter a year between now and 200 years in the past',
            },
            {
                name: `standardAssets.${index}.assetCondition`,
                inputType: 'condition',
                label: 'Asset Condition',
                timeseriesConfig: {
                    reassess: {
                        modalLabel: 'Condition',
                        isReassessable: (+(currentCondition || '0') || 0) > 0,
                        reassessCall: (newCondition, assessedOn) => reassessStandardAssetCondition(newCondition, assessedOn, currentId),
                    },
                    graphConfig: {
                        type: ChartType.single,
                        chartData: {
                            title: getName(index),
                            data: {
                                datasets: [
                                    {
                                        type: ConditionType.Observed,
                                        values: getConditionDataset(index),
                                        years: getYears(index),
                                    },
                                    {
                                        type: ConditionType.AgeAdjusted,
                                        values: getAgeAdjustedConditionDataset(index),
                                        years: getYears(index),
                                    },
                                ],
                            },
                        },
                    },
                },
            },
            {
                name: `standardAssets.${index}.ageAdjustedCondition`,
                inputType: 'condition',
                label: 'Age Adjusted Asset Condition',
                isHidden: isEditing ? true : false,
            },
            {
                name: `standardAssets.${index}.assetType`,
                inputType: 'dropdown',
                label: `${assetClass} Type`,
                choices: assetTemplate.assetTypes,
                required: true,
                onChange: (e) => handleAssetTypeChange(e),
            },
        ];
        return formInputs;
    };
    return (_jsxs(_Fragment, { children: [canHaveSelect && (_jsx(DynamicInput, { inputType: 'dropdown', handleInputChange: setNameOption, choices: nameOptions, data: nameOption, editMode: true, label: `Select ${assetClass}`, placeholder: classHasMultipleAssets.includes(assetClass)
                    ? ''
                    : `Select ${assetClass}` })), indexes.map((index, i) => (_jsx(Box, Object.assign({ display: nameOptions.indexOf(nameOption) === i ? 'block' : 'none', mt: '12px' }, { children: _jsx(SingleAssetDetails, { index: index, isFirstAssetOfClass: i === 0 }) }), `asset-form-${i}`)))] }));
};
