import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDisclosure } from '@chakra-ui/react';
import { AddPhotoGridItem } from './AddPhotoGridItem';
import { AddPhotoGridItemDisabled } from './AddPhotoGridItemDisabled';
import { PhotoRow } from './PhotoRow';
import { ThumbnailItem } from './ThumbnailItem';
export const PhotoGalleryModalRow = ({ title, photos, canAddPhoto, canEdit, renderAddPhotoModal, }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const renderAddPhotoButton = (addPhotoModal) => {
        if (!canEdit) {
            return _jsx(_Fragment, {});
        }
        if (addPhotoModal) {
            return _jsx(AddPhotoGridItem, { onClick: onOpen });
        }
        else {
            return _jsx(AddPhotoGridItemDisabled, {});
        }
    };
    return (_jsx(PhotoRow, Object.assign({ title: title }, { children: _jsxs(_Fragment, { children: [canAddPhoto && renderAddPhotoButton(renderAddPhotoModal), photos &&
                    photos.map((photo, index) => (_jsx(ThumbnailItem, { photo: photo, maxH: '220px' }, index + photo.name))), canAddPhoto &&
                    renderAddPhotoModal &&
                    renderAddPhotoModal(isOpen, onClose)] }) })));
};
