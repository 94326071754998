var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useFacilityService } from '@frontend/domain/services/FacilityService';
import { useState } from 'react';
export const useFacility = () => {
    const { platformApi } = useDependencies();
    const { facility } = useFacilityService({ platformApi });
    const [isDownloading, setIsDownloading] = useState(false);
    const exportCSVFiles = (facilityIdentity, fileName, type, organizationIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsDownloading(true);
        return yield facility
            .exportCSVFiles(facilityIdentity, fileName, type, organizationIdentity)
            .then((response) => {
            return response;
        })
            .finally(() => setIsDownloading(false));
    });
    return { exportCSVFiles, isDownloading };
};
