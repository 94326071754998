import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFacilitiesSpacesAssetsByOrg } from '@/services/api/facilityAPI';
import { IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useToast, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { slgqColors } from '@frontend/design-system/theme/theme';
import { GalleryProvider } from '../../../../../contexts/Photo/GalleryProvider';
import { usePhotoContext } from '../../../../../contexts/Photo/PhotoContext';
import { FilterButton } from './FilterPhotosModal/FilterButton';
import { FilterCardsSection } from './FilterPhotosModal/FilterCardsSection';
import { PhotoGalleryModalRow, } from './PhotoGalleryModalRow';
import { PhotoViewer } from './PhotoViewer';
const PhotoGalleryModal = ({ canEdit, children, name, isOpen, orgIdentity, onClose, }) => {
    const [panoramicSelected, setPanoramicSelected] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [spaces, setSpaces] = useState([]);
    const [assets, setAssets] = useState([]);
    const toast = useToast();
    const { currentPhotos, setCurrentFilters, selectedPhoto, removeSinglePhoto, setSelectedFilters, currentFilters, } = usePhotoContext();
    const onCloseSetPhotoUndefined = () => {
        setPanoramicSelected(false);
        removeSinglePhoto();
        setSelectedFilters({});
        setCurrentFilters({});
        onClose();
    };
    useEffect(() => {
        if ((currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.favorites) === true ||
            ((currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.tags) && currentFilters.tags.length > 0)) {
            setIsFiltering(true);
        }
        else {
            setIsFiltering(false);
        }
        return () => {
            setIsFiltering(false);
        };
    }, [currentFilters]);
    const onImageLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        const ratio = offsetWidth / offsetHeight;
        // if wide ratio set panaramic photo
        setPanoramicSelected(Math.round(ratio * 100) / 100 === 2);
    };
    useEffect(() => {
        if (orgIdentity && isOpen) {
            getFacilitiesSpacesAssetsByOrg(orgIdentity)
                .then((fetchedData) => {
                const facilitiesArray = fetchedData.map((facility) => {
                    return {
                        identity: facility.identity,
                        name: facility.name,
                    };
                });
                const spacesAssetsArray = fetchedData.flatMap((facility) => {
                    if (facility.spaces) {
                        return facility.spaces;
                    }
                    else
                        return [];
                });
                const spacesArray = spacesAssetsArray.map((space) => {
                    return {
                        identity: space.identity,
                        facilityIdentity: space.facilityIdentity,
                        constrYear: space.constrYear,
                        name: space.name,
                    };
                });
                const assetsArray = spacesAssetsArray.flatMap((space) => {
                    if (space.assets) {
                        return space.assets;
                    }
                    else
                        return [];
                });
                setFacilities(facilitiesArray);
                setSpaces(spacesArray);
                setAssets(assetsArray);
            })
                .catch((error) => {
                console.error(error);
                toast({
                    title: 'Something went wrong with fetching facilities; try again later.',
                    status: 'error',
                    isClosable: true,
                });
            });
        }
    }, [orgIdentity]);
    const renderPhotoRows = (showFilter, photos, children) => {
        if (showFilter) {
            return (_jsxs(_Fragment, { children: [_jsx(FilterCardsSection, {}), _jsx(PhotoGalleryModalRow, { title: '', photos: photos, canAddPhoto: false, canEdit: canEdit })] }));
        }
        else {
            return _jsx(_Fragment, { children: children });
        }
    };
    const renderModalContent = () => {
        if (!selectedPhoto) {
            return (_jsxs(ModalContent, Object.assign({ p: '37px', bg: slgqColors.primWhite, minH: '80%', maxW: '90vw' }, { children: [_jsxs(ModalHeader, Object.assign({ as: 'h1', fontSize: '36px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, { children: [name, " Photo Gallery", _jsx(FilterButton, { showClassification: false })] })), _jsx(ModalCloseButton, { onClick: () => {
                            setSelectedFilters({});
                            setCurrentFilters({});
                        } }), _jsx(ModalBody, Object.assign({ pb: '40px', pt: '0px', overflow: 'auto' }, { children: renderPhotoRows(isFiltering, currentPhotos, children) }))] })));
        }
        else {
            return (_jsxs(ModalContent, Object.assign({ p: '37px', pt: 0, bg: slgqColors.primWhite, maxW: '90vw' }, { children: [_jsx(ModalHeader, { children: _jsx(IconButton, { bg: '#faf9f8', onClick: () => {
                                removeSinglePhoto();
                                setPanoramicSelected(false);
                            }, icon: _jsx(AiOutlineArrowLeft, {}), "aria-label": 'return to gallery' }) }), _jsx(ModalCloseButton, { onClick: onCloseSetPhotoUndefined }), _jsx(ModalBody, Object.assign({ pb: '40px', pt: '0px', overflow: 'auto' }, { children: _jsx(PhotoViewer, { panoramicSelected: panoramicSelected, onImageLoad: onImageLoad, onClosePhotoViewer: removeSinglePhoto }) }))] })));
        }
    };
    return (_jsx(GalleryProvider, Object.assign({ allFacilities: facilities, allSpaces: spaces, allAssets: assets }, { children: _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onCloseSetPhotoUndefined, size: 'xl', scrollBehavior: 'inside', isCentered: true }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), renderModalContent()] })) })));
};
export default PhotoGalleryModal;
