var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPlans } from '@/services/api/facilityAPI';
import { HStack, Stack, useToast } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { PlannerSettingsProvider } from '@frontend/domain/contexts/Settings/PlannerSettingsProvider';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { appValues } from '../../config/constants';
import { FacilityPageProvider } from '../../contexts/Facilities/FacilityPageProvider';
import { FacilityNotes } from './Canvas/FacilityNotes';
import FloorPlanWrapper from './Canvas/FloorPlanWrapper';
import { FacilityPageHeader } from './FacilityPageHeader';
import { DetailsSidebar } from './Sidebar/DetailsSidebar/DetailsSidebar';
import { PlansSidebar } from './Sidebar/PlansSidebar/PlansSidebar';
export const FacilityPage = () => {
    // TODO:  an api with 'compressed' in the path is being called and failing; it doesn't seem to be affecting anything. The use of this will likely be removed during the photo migration work. Otherwise, it has been noted in card 4194 as an api to look at for cleanup.
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const { fid } = useParams();
    const paramsFacilityId = fid ? Number(fid) : undefined;
    // TODO: think through not needing to grab facility identity from route
    const getSiteAndFacility = (org) => {
        let foundFacility = undefined;
        let foundSite = undefined;
        if (org) {
            for (const site of org.sites) {
                for (const facility of site.facilities) {
                    if (facility.identity === paramsFacilityId) {
                        foundFacility = facility;
                        foundSite = site;
                        break; // Exit the inner loop if facility is found
                    }
                }
                if (foundFacility)
                    break; // Exit the outer loop if facility is found
            }
        }
        return { facility: foundFacility, site: foundSite };
    };
    const { site, facility } = getSiteAndFacility(organizationDetails);
    const [currentPlans, setCurrentPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();
    useEffect(() => {
        if ((facility === null || facility === void 0 ? void 0 : facility.identity) &&
            (!currentPlans || currentPlans.length === 0)) {
            fetchPlans(facility.identity);
        }
    }, [facility === null || facility === void 0 ? void 0 : facility.identity]);
    const fetchPlans = (facilityIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const res = yield getPlans(facilityIdentity);
            setCurrentPlans(res);
        }
        catch (err) {
            console.error('couldnt fetch plans: ', err);
            toast({
                title: 'Failed to fetch plans',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            setIsLoading(false);
        }
    });
    const updateCurrentPlans = (
    // TODO: replace selectedPlan with identity when createSpace endpont availiable
    selectedPlan, updatedPlan) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = prevPlans.map((plan) => {
                // TODO: change this to use filter
                if (plan.identity !== selectedPlan.identity) {
                    return plan;
                }
                return updatedPlan;
            });
            return updatedPlans;
        });
    });
    const handlePlanCreate = (planToCreate) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = [...prevPlans, planToCreate];
            return updatedPlans;
        });
    });
    const handlePlanDelete = (planToDelete) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = prevPlans.filter((plan) => plan.identity !== planToDelete.identity);
            return updatedPlans;
        });
    });
    return (_jsxs(FacilityPageProvider, Object.assign({ plans: currentPlans }, { children: [_jsxs(Stack, Object.assign({ bg: 'misc.bgGrey', color: 'slgq.primBlue', h: '100%', spacing: 0, w: '100%', zIndex: '1', overflow: 'hidden' }, { children: [_jsx(FacilityPageHeader, { orgId: organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.identity, orgName: organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.name, siteName: site === null || site === void 0 ? void 0 : site.name, facilityName: facility === null || facility === void 0 ? void 0 : facility.name }), _jsxs(HStack, Object.assign({ h: `calc(100vh - ${appValues.HEADER_HEIGHT_MAIN}px)`, spacing: 0 }, { children: [_jsx(PlansSidebar, { plans: currentPlans, updateCurrentPlans: updateCurrentPlans, handlePlanCreate: handlePlanCreate, handlePlanDelete: handlePlanDelete, facilityIdentity: facility === null || facility === void 0 ? void 0 : facility.identity }), _jsx(HStack, Object.assign({ sx: { touchAction: 'none' }, h: `calc(100vh - ${appValues.HEADER_HEIGHT_MAIN}px)`, w: '100vw' }, { children: _jsxs(Splitter, Object.assign({ layout: 'vertical', style: {
                                        height: '100%',
                                        width: '100%',
                                    } }, { children: [_jsx(SplitterPanel, Object.assign({ style: { overflowX: 'hidden' }, size: 99.9 }, { children: currentPlans && currentPlans.length > 0 && (
                                            // TODO: potential refactor to call a hook instead of using the provider
                                            _jsx(PlannerSettingsProvider, Object.assign({ orgIdentity: selectedOrganizationIdentity }, { children: _jsx(FloorPlanWrapper, {}) }))) })), _jsx(SplitterPanel, Object.assign({ style: { overflowX: 'hidden' }, size: 0.1 }, { children: facility && (_jsx(FacilityNotes, { facilityId: facility.id, note: facility.note })) }))] })) })), selectedOrganizationIdentity && (_jsx(DetailsSidebar, { orgIdentity: selectedOrganizationIdentity, facilityIdentity: facility === null || facility === void 0 ? void 0 : facility.identity }))] }))] })), _jsx(LoadingWithControl, { isLoading: isLoading })] })));
};
