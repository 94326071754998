// TODO: once the changes to unregister are in prod; this file and the line of code unregistering in the index can be removed
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
console.log('This is serviceWorkerRegistration file.');
export function unregister() {
    if ('serviceWorker' in navigator) {
        console.log('Service Worker is in navigator');
        navigator.serviceWorker.ready
            .then((registration) => {
            console.log('Service Worker is ready, so we are unregistering');
            registration.unregister().then(() => {
                // setUpdateAvail(true); // only needed if we support previous versions of static builds
                console.log('Service worker unregistered');
            });
            if (caches) {
                console.log('Clearing remaining caches from service worker');
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => __awaiter(this, void 0, void 0, function* () {
                    yield Promise.all(names.map((name) => caches.delete(name))).then(() => console.log('Caches removed', caches.keys()));
                }));
            }
        })
            .catch((error) => {
            console.error(error.message);
        });
    }
}
