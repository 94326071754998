import { rest } from 'msw';
import { makePlan, mockFetchClientSpacesPlanData } from './testObjects';
export const handlers = [
    rest.get('*/:fidentity/plans', (req, res, ctx) => {
        const plans = [
            makePlan(),
            makePlan({ id: 'plan2', identity: 2, name: 'testPlan 2' }),
        ];
        return res(ctx.json(plans));
    }),
    rest.get('*/plans/:planId', (req, res, ctx) => {
        return res(ctx.json(mockFetchClientSpacesPlanData));
    }),
    rest.get('*/assets/facilityWide/*', (req, res, ctx) => {
        return res(ctx.json([
            makePlan(),
            makePlan({ id: 'plan2', identity: 2, name: 'testPlan 2' }),
        ]));
    }),
    rest.get('*/phototags/*', (req, res, ctx) => {
        return res(ctx.json({
            tagIdentity: 1,
            name: 'Photo tag 1',
            color: '#F6862A',
        }));
    }),
    rest.get('*/photos/*/organizationPhotos', (req, res, ctx) => {
        return res(ctx.json([]));
    }),
    rest.put('*/note', (req, res, ctx) => {
        return res(ctx.json({
            id: '1',
            facilityIdentity: 1,
            name: 'Test Facility 1',
            squareFootage: 0,
            totalSquareFootageIncludingAdditions: 0,
            note: 'note',
            plans: [],
            spaces: [],
            assets: [],
        }));
    }),
];
