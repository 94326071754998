var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast, } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsPlusCircle } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { variants } from '@frontend/design-system/theme/theme';
import { useFacilityContext } from '../../../../contexts/Facilities/FacilityPageContext';
import { createPlan } from '../../../../services/api/facilityAPI';
import { updatePlan } from '../../../../services/api/planAPI';
import { usePhotoServiceHook } from '../../../../store/photoServiceHook';
import { toCleanFileName, toCurrentYYYYMMDDHHMMSSMSMS, } from '../../../../utils/photoFileUtils';
export const UploadPlanModal = ({ isOpen, onClose, editMode, updateCurrentPlans, handlePlanCreate, }) => {
    var _a, _b, _c;
    const { selectedPlan, selectPlan } = useFacilityContext();
    const { fidentity } = useParams();
    const facilityIdentity = Number(fidentity);
    const toast = useToast();
    const { register, handleSubmit, reset, formState: { errors }, } = useForm();
    const planRef = useRef(null);
    const planNameRegister = register(PLAN_NAME_KEY, { required: true });
    const [file, setFile] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { uploadPhotoToBlobStorage } = usePhotoServiceHook();
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        const plan = (_e = (_d = planRef.current) === null || _d === void 0 ? void 0 : _d.files) === null || _e === void 0 ? void 0 : _e.item(0);
        const dateTimeString = toCurrentYYYYMMDDHHMMSSMSMS();
        setIsSubmitting(true);
        yield uploadPlanImageToStore(plan, dateTimeString);
        try {
            // Duplicate definition of fileName look to refactor
            const cleanedFileName = (plan === null || plan === void 0 ? void 0 : plan.name) && toCleanFileName(plan.name);
            const fileName = `${dateTimeString}${cleanedFileName}`;
            if (facilityIdentity) {
                const createdPlan = yield createPlan(facilityIdentity, {
                    name: data.planName,
                    planFileName: fileName,
                });
                handlePlanCreate(createdPlan);
                selectPlan(createdPlan);
                toast({
                    title: 'Plan created',
                    status: 'success',
                    isClosable: true,
                });
                setFile(undefined);
                onClose();
                reset();
            }
        }
        catch (_f) {
            toast({
                title: 'Error creating plan',
                description: 'Please check that all fields are entered and try again.',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            setIsSubmitting(false);
        }
    });
    const uploadPlanImageToStore = (planFile, dateTimeString) => __awaiter(void 0, void 0, void 0, function* () {
        if (planFile) {
            try {
                yield uploadPhotoToBlobStorage({
                    file: planFile,
                    appendString: dateTimeString,
                });
                toast({
                    title: 'File uploaded',
                    status: 'success',
                    isClosable: true,
                });
            }
            catch (_g) {
                toast({
                    title: 'Error uploading file',
                    description: 'Please try again.',
                    status: 'error',
                    isClosable: true,
                });
            }
        }
        else {
            toast({
                title: 'Error uploading file',
                description: 'Please try again.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const updatePlanImage = () => __awaiter(void 0, void 0, void 0, function* () {
        var _h;
        const plan = ((_h = planRef.current) === null || _h === void 0 ? void 0 : _h.files) && planRef.current.files[0];
        const dateTimeString = toCurrentYYYYMMDDHHMMSSMSMS();
        setIsSubmitting(true);
        yield uploadPlanImageToStore(plan, dateTimeString);
        try {
            if (selectedPlan) {
                const cleanedFileName = (plan === null || plan === void 0 ? void 0 : plan.name) && toCleanFileName(plan.name);
                const updatedPlan = yield updatePlan(selectedPlan.id, {
                    name: selectedPlan.name,
                    planFileName: `${dateTimeString}${cleanedFileName}`,
                });
                selectPlan(updatedPlan);
                updateCurrentPlans(selectedPlan, updatedPlan);
                toast({
                    title: 'Plan updated',
                    status: 'success',
                    isClosable: true,
                });
                setFile(undefined);
                onClose();
                reset();
            }
        }
        catch (_j) {
            toast({
                title: 'Error updating plan',
                description: 'Please check that all fields are entered and try again.',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            setIsSubmitting(false);
        }
    });
    useEffect(() => {
        var _a;
        if (((_a = planRef.current) === null || _a === void 0 ? void 0 : _a.files) && planRef.current.files[0]) {
            setFile(planRef.current.files[0]);
        }
    }, [(_a = planRef.current) === null || _a === void 0 ? void 0 : _a.files]);
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: () => {
            onClose();
            setFile(undefined);
            reset();
        } }, { children: [_jsx(ModalOverlay, {}), _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: editMode
                                ? 'Edit Facility Plan'
                                : 'Upload Facility Plan' }), _jsx(ModalCloseButton, {}), _jsxs(ModalBody, { children: [editMode ? (_jsx(Text, { children: selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.name })) : (_jsxs(_Fragment, { children: [_jsx(Input, Object.assign({ placeholder: 'Facility Plan Name', variant: variants.fcaBaseStyle }, planNameRegister)), errors.planName && (_jsx(Text, Object.assign({ variant: variants.fcaError }, { children: "Please enter plan name." })))] })), _jsx(Button, Object.assign({ onClick: () => {
                                        var _a;
                                        (_a = planRef === null || planRef === void 0 ? void 0 : planRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                    }, leftIcon: _jsx(BsPlusCircle, {}), variant: 'ghost', display: 'inline-block' }, { children: "Upload Facility Plan File" })), _jsx("input", { type: 'file', style: { display: 'none' }, accept: 'image/*', ref: planRef, onChange: () => {
                                        var _a;
                                        if (((_a = planRef.current) === null || _a === void 0 ? void 0 : _a.files) &&
                                            planRef.current.files[0]) {
                                            setFile(planRef.current.files[0]);
                                        }
                                    }, "data-testid": 'file-input' }), _jsx(Text, { children: "File being uploaded:" }), _jsx(Text, { children: file === null || file === void 0 ? void 0 : file.name })] }), _jsx(ModalFooter, { children: editMode ? (_jsx(Button, Object.assign({ isDisabled: !((_b = planRef.current) === null || _b === void 0 ? void 0 : _b.files) || isSubmitting, onClick: updatePlanImage }, { children: "Submit" }))) : (_jsx(Button, Object.assign({ type: 'submit', isDisabled: !((_c = planRef.current) === null || _c === void 0 ? void 0 : _c.files) || isSubmitting }, { children: "Submit" }))) })] }) }))] })));
};
const PLAN_NAME_KEY = 'planName';
