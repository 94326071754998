import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterCardsSection } from '@/pages/Main/Sidebar/DetailsSidebar/PhotoGalleryModal/FilterPhotosModal/FilterCardsSection';
import { Box, ButtonGroup, HStack, VStack } from '@chakra-ui/react';
import { DownloadPhotoButton } from '../../../components/buttons/DownloadPhotoButton';
import { MovePhotoButton } from '../../../components/buttons/MovePhotoButton';
import { usePhotoSelector } from '../../../contexts/Photo/PhotoSelectContext';
import { FilterButton } from '../../Main/Sidebar/DetailsSidebar/PhotoGalleryModal/FilterPhotosModal/FilterButton';
import { GalleryGrid } from './GalleryGrid';
import { SelectLabel } from './SelectLabel';
export const Gallery = ({ orgIdentity }) => {
    const { isSelecting, selectLimit, selectedPhotos } = usePhotoSelector();
    return (_jsxs(Box, { children: [_jsxs(HStack, Object.assign({ px: '40px', mt: '20px', height: '40px', justifyContent: 'right' }, { children: [isSelecting && (_jsx(_Fragment, { children: _jsx(SelectLabel, { label: 'Select your photos', selected: selectedPhotos.length, max: selectLimit }) })), _jsxs(ButtonGroup, Object.assign({ display: 'flex' }, { children: [!isSelecting && _jsx(FilterButton, {}), _jsx(MovePhotoButton, {}), _jsx(VStack, { children: _jsx(DownloadPhotoButton, { orgIdentity: orgIdentity }) })] }))] })), _jsx(Box, Object.assign({ p: '50px 40px' }, { children: _jsxs(Box, Object.assign({ display: 'block' }, { children: [_jsx(FilterCardsSection, {}), _jsx(GalleryGrid, {})] })) }))] }));
};
