var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FileDownloadButton } from '@frontend/design-system/components/Buttons/FileDownloadButton';
import { useFcaPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useFacility } from '../../../contexts/Facilities/FacilityHook';
export const CsvFileExporter = ({ facilityId, facilityName, organizationIdentity, }) => {
    const { exportCSVFiles, isDownloading } = useFacility();
    const { canDownload } = useFcaPermissions(facilityId);
    const downloadCSVFiles = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield exportCSVFiles(facilityId, `${facilityName} FCA Export.zip`, 'application/zip', organizationIdentity);
    });
    return (_jsx(_Fragment, { children: canDownload && (_jsx(FileDownloadButton, { fetchFile: downloadCSVFiles, buttonName: 'Download CSV', isDisabled: isDownloading })) }));
};
