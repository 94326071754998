var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useToast, } from '@chakra-ui/react';
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
export const DeletePhotoModal = ({ isOpen, onClose, onClosePhotoViewer, }) => {
    const toast = useToast();
    const { deleteSelectedPhoto, selectedPhoto } = usePhotoContext();
    const handleDeletePhoto = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield deleteSelectedPhoto(id);
            onClosePhotoViewer();
            toast({
                title: 'Photo deleted',
                status: 'success',
                isClosable: true,
            });
        }
        catch (_a) {
            toast({
                title: 'Error deleting photo',
                status: 'error',
                isClosable: true,
            });
        }
    });
    return (_jsxs(Modal, Object.assign({ closeOnEsc: false, closeOnOverlayClick: false, isOpen: isOpen, isCentered: true, onClose: onClose, size: 'xl' }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ bg: slgqColors.primWhite, padding: '40px' }, { children: [_jsx(ModalHeader, Object.assign({ color: slgqColors.secGrey, fontSize: '36px', fontWeight: 400, padding: '0px' }, { children: "Delete Photo" })), _jsx(ModalBody, Object.assign({ p: '0px', mt: '20px', mb: '40px' }, { children: _jsx(Text, Object.assign({ fontSize: '20px' }, { children: "You are about to remove a photo from the timeline. Are you sure you want to continue?" })) })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(Flex, Object.assign({ w: '100%' }, { children: [_jsx(Button, Object.assign({ fontSize: '14px', onClick: () => onClose(), onTouchEnd: () => onClose(), type: 'button', variant: variants.whiteBtn }, { children: "CANCEL" })), _jsx(Spacer, {}), _jsx(Button, Object.assign({ bg: '#DA1100', borderColor: '#DA1100', color: slgqColors.primWhite, fontSize: '14px', onClick: () => {
                                        selectedPhoto &&
                                            handleDeletePhoto(selectedPhoto.id);
                                        onClose();
                                    }, onTouchEnd: () => {
                                        selectedPhoto &&
                                            handleDeletePhoto(selectedPhoto.id);
                                        onClose();
                                    }, type: 'button', variant: variants.whiteBtn }, { children: "DELETE" }))] })) }))] }))] })));
};
