import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, } from '@chakra-ui/react';
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
export const DeleteSpaceModal = ({ isOpen, onClose, }) => {
    const { currentSpaces, selectedSpaceIdentity, deleteSpace } = useFacilityContext();
    const space = currentSpaces.find((space) => space.identity === selectedSpaceIdentity);
    const handleDeleteSpace = () => {
        if (selectedSpaceIdentity) {
            deleteSpace(selectedSpaceIdentity);
        }
    };
    return (_jsxs(Modal, Object.assign({ closeOnEsc: false, closeOnOverlayClick: false, isOpen: isOpen, isCentered: true, onClose: onClose, size: 'xl' }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ bg: slgqColors.primWhite, padding: '40px' }, { children: [_jsx(ModalHeader, Object.assign({ color: slgqColors.secGrey, fontSize: '36px', fontWeight: 400, padding: '0px' }, { children: "Delete Space" })), _jsx(ModalBody, Object.assign({ p: '0px', mt: '20px', mb: '40px' }, { children: _jsxs(Text, Object.assign({ fontSize: '20px' }, { children: ["You are about to delete space \"", space === null || space === void 0 ? void 0 : space.name, "\" and all of its associated assets including any facility wide assets. Are you sure you want to continue?"] })) })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(Flex, Object.assign({ w: '100%' }, { children: [_jsx(Button, Object.assign({ fontSize: '14px', onClick: () => onClose(), onTouchEnd: () => onClose(), type: 'button', variant: variants.whiteBtn }, { children: "CANCEL" })), _jsx(Spacer, {}), _jsx(Button, Object.assign({ bg: '#DA1100', borderColor: '#DA1100', color: slgqColors.primWhite, fontSize: '14px', onClick: () => {
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        handleDeleteSpace();
                                        onClose();
                                    }, onTouchEnd: () => {
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        handleDeleteSpace();
                                        onClose();
                                    }, type: 'button', variant: variants.whiteBtn }, { children: "DELETE" }))] })) }))] }))] })));
};
