// TODO: refactor the use of this. Should it include ground assets?
export var StandardAssetTypes;
(function (StandardAssetTypes) {
    StandardAssetTypes["flooring"] = "FLOORING";
    StandardAssetTypes["ceiling"] = "CEILING";
    StandardAssetTypes["windows"] = "WINDOWS";
    StandardAssetTypes["casework"] = "CASEWORK";
    StandardAssetTypes["interiorDoors"] = "INTERIOR_DOORS";
    StandardAssetTypes["exteriorDoors"] = "EXTERIOR_DOORS";
    StandardAssetTypes["lighting"] = "LIGHTING";
})(StandardAssetTypes || (StandardAssetTypes = {}));
export var AssetClassType;
(function (AssetClassType) {
    AssetClassType["PINNED"] = "Pinned";
    AssetClassType["STANDARD"] = "Standard";
    AssetClassType["FACILITY"] = "Facility";
})(AssetClassType || (AssetClassType = {}));
export var PageModes;
(function (PageModes) {
    PageModes[PageModes["VIEW"] = 0] = "VIEW";
    PageModes[PageModes["EDIT"] = 1] = "EDIT";
    PageModes[PageModes["ADD_SPACE"] = 2] = "ADD_SPACE";
    PageModes[PageModes["ADD_ASSET"] = 3] = "ADD_ASSET";
    PageModes[PageModes["LOADING"] = 4] = "LOADING";
})(PageModes || (PageModes = {}));
export var SpaceCategoryEnum;
(function (SpaceCategoryEnum) {
    SpaceCategoryEnum["FACILITY"] = "FACILITY";
    SpaceCategoryEnum["GROUNDS"] = "GROUNDS";
})(SpaceCategoryEnum || (SpaceCategoryEnum = {}));
