import { createSlice } from '@reduxjs/toolkit';
export const initialServiceWorkerState = {
    updateAvail: false,
};
export const ServiceWorkerSlice = createSlice({
    name: 'ServiceWorker',
    initialState: initialServiceWorkerState,
    reducers: {
        setUpdateAvail: (state, { payload: updateAvail }) => {
            state.updateAvail = updateAvail;
        },
    },
});
export const selectUpdateAvail = (state) => {
    return state.serviceWorker.updateAvail;
};
export const { setUpdateAvail } = ServiceWorkerSlice.actions;
export default ServiceWorkerSlice.reducer;
