import { createSlice } from '@reduxjs/toolkit';
const plans = undefined;
export const facilitySlice = createSlice({
    name: 'facility',
    initialState: {
        plans: plans,
    },
    reducers: {
        setPlans: (state, { payload: plans }) => {
            state.plans = plans;
        },
    },
});
export const { setPlans } = facilitySlice.actions;
export default facilitySlice.reducer;
