import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDisclosure } from '@chakra-ui/react';
import { usePhotoContext } from '../../../../../contexts/Photo/PhotoContext';
import { useSpaceContext } from '../../../../../contexts/Spaces/SpaceContext';
import { PhotoType, SetUpPhotoModal } from '../AddPhotoModal/SetUpPhotoModal';
import PhotoCarousel from './PhotoCarousel';
import PhotoGalleryModal from './PhotoGalleryModal';
import { PhotoGalleryModalRow, } from './PhotoGalleryModalRow';
const SpacePhotoCarousel = ({ spaceIdentity, spaceName, standardAssetClass, assetTemplates, canEdit, orgIdentity, }) => {
    const { isOpen: isOpenGallery, onClose: onCloseGallery, onOpen: onOpenGallery, } = useDisclosure();
    const { currentPhotos } = usePhotoContext();
    const { getStandardAssetInClass } = useSpaceContext();
    const createPhotoRowList = (photos = [], assetClassTemplates, assetClassSelected) => {
        const photoRowList = [];
        // If looking at a standard asset, first row should be that asset class
        if (assetClassSelected) {
            const selectedClassAsset = getStandardAssetInClass(assetClassSelected);
            if (selectedClassAsset) {
                photoRowList.push({
                    title: assetClassSelected,
                    photos: getPhotosByClass(assetClassSelected, photos),
                    canEdit,
                    addPhotoModal: addAssetPhotoModal(selectedClassAsset.identity, selectedClassAsset.name),
                });
            }
        }
        const favoritePhotos = photos.filter((photo) => photo.favorite);
        // Only show favorites row if there are favorites
        if (favoritePhotos.length > 0) {
            photoRowList.push({
                title: 'Favorites',
                photos: photos.filter((photo) => photo.favorite),
                canEdit,
            });
        }
        // General row shows all space only photos
        photoRowList.push({
            title: 'General',
            photos: photos.filter((photo) => !photo.asset),
            canEdit,
            addPhotoModal: addSpacePhotoModal,
        });
        // Show rest of standard asset photos by asset class
        assetClassTemplates
            .filter((template) => assetClassSelected !== template.assetClass)
            .forEach((template) => {
            const selectedClassAsset = getStandardAssetInClass(template.assetClass);
            photoRowList.push({
                title: template.assetClass,
                photos: getPhotosByClass(template.assetClass, photos),
                canEdit,
                addPhotoModal: selectedClassAsset
                    ? addAssetPhotoModal(selectedClassAsset.identity, selectedClassAsset.name)
                    : undefined,
            });
        });
        return photoRowList;
    };
    const getPhotosByClass = (assetClass, photos) => {
        return photos.filter((photo) => { var _a; return ((_a = photo.asset) === null || _a === void 0 ? void 0 : _a.assetClass) === assetClass; });
    };
    const addSpacePhotoModal = (isOpen, onClose) => {
        return (_jsx(SetUpPhotoModal, { photoType: PhotoType.SPACE, canEdit: canEdit, isOpen: isOpen, onClose: onClose, spaceIdentity: spaceIdentity, spaceName: spaceName }));
    };
    // TODO: this is confusing can it be made less confusing??
    //   This method returns a method that satisfies the type needed
    //   for PhotoGalleryModalRowItem.addPhotoModal
    const addAssetPhotoModal = (assetIdentity, assetName) => {
        return (isOpen, onClose) => (_jsx(SetUpPhotoModal, { photoType: PhotoType.STANDARD_ASSET, canEdit: canEdit, isOpen: isOpen, onClose: onClose, spaceIdentity: spaceIdentity, assetIdentity: assetIdentity, assetName: assetName }));
    };
    const getCarouselDisplayPhotos = (currentPhotos, standardAssetClass) => {
        const photos = currentPhotos || [];
        if (!standardAssetClass) {
            return photos.filter((photo) => !photo.asset);
        }
        else {
            return photos.filter((photo) => { var _a; return ((_a = photo.asset) === null || _a === void 0 ? void 0 : _a.assetClass) === standardAssetClass; });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(PhotoCarousel, { openGallery: onOpenGallery, photos: getCarouselDisplayPhotos(currentPhotos, standardAssetClass) }), _jsx(PhotoGalleryModal, Object.assign({ name: spaceName, isOpen: isOpenGallery, onClose: onCloseGallery, canEdit: canEdit, orgIdentity: orgIdentity }, { children: createPhotoRowList(currentPhotos, assetTemplates, standardAssetClass).map((row, index) => (_jsx(PhotoGalleryModalRow, { title: row.title, photos: row.photos, canAddPhoto: true, canEdit: canEdit, renderAddPhotoModal: row.addPhotoModal }, `gallery_row_${standardAssetClass}_${index}`))) }))] }));
};
export default SpacePhotoCarousel;
