import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Stack, } from '@chakra-ui/react';
import React from 'react';
export const RadioGroupInput = ({ label, editMode, data, choices, errorMessage, onInputChange, required, isDisabled, variant = undefined, }) => {
    const convertToTitleCase = (str) => {
        const lowerCaseStr = str.toLowerCase();
        const capitalizedStr = lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
        return capitalizedStr;
    };
    return (_jsxs(FormControl, Object.assign({ isRequired: required }, { children: [_jsx(FormLabel, Object.assign({ variant: variant }, { children: label })), _jsx(RadioGroup, Object.assign({ value: data, onChange: onInputChange, isDisabled: !editMode || isDisabled ? true : false }, { children: _jsx(Stack, Object.assign({ direction: 'column' }, { children: choices.map((choice, i) => (_jsx(Radio, Object.assign({ value: choice, colorScheme: 'orange', isChecked: data === choice }, { children: _jsx(Box, Object.assign({ fontSize: '14px' }, { children: convertToTitleCase(choice) })) }), `radio_option_${i}_${choice}`))) })) })), errorMessage && (_jsx(FormErrorMessage, { children: errorMessage }))] })));
};
