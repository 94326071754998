import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Circle, FormControl, FormLabel, HStack, Select, Text, } from '@chakra-ui/react';
import { measureColors } from '../../../../config/constants';
import { variants } from '@frontend/design-system/theme/theme';
import { conditions } from './utility/ConditionArray';
export const ConditionSelectInput = ({ label, data, editable, onInputChange, required, isDisabled, }) => {
    const renderCircle = (color, text) => {
        return (_jsxs(HStack, { children: [_jsx(Circle, { mr: '-20px', size: '12px', bg: color }), _jsx(Text, Object.assign({ variant: variants.detailsInputText }, { children: text }))] }));
    };
    return (_jsx(Box, Object.assign({ h: '100%', w: '100%' }, { children: _jsxs(FormControl, Object.assign({ isRequired: required }, { children: [_jsx(FormLabel, Object.assign({ variant: variants.fcaBaseStyle }, { children: label })), _jsx(Select, Object.assign({ position: editable ? 'inherit' : 'absolute', visibility: editable ? 'visible' : 'hidden', pointerEvents: editable ? 'auto' : 'none', isDisabled: isDisabled, placeholder: label, value: data, fontSize: '14px', onChange: (e) => onInputChange(e.target.value), variant: variants.fcaBaseStyle }, { children: conditions.map((condition, i) => {
                        return (_jsx("option", Object.assign({ value: condition.identity }, { children: condition.name }), `${label}.choice.${i}`));
                    }) })), !editable && (_jsxs(_Fragment, { children: [data === null && '-', data === 1 &&
                            renderCircle(measureColors.MEASURE_GREEN, '1 (Excellent)'), data === 2 &&
                            renderCircle(measureColors.MEASURE_GRELLO, '2 (Acceptable)'), data === 3 &&
                            renderCircle(measureColors.MEASURE_YELLOW, '3 (Caution)'), data === 4 &&
                            renderCircle(measureColors.MEASURE_ORANGE, '4 (Alert)'), data === 5 &&
                            renderCircle(measureColors.MEASURE_RED, '5 (Alarm)')] }))] })) })));
};
