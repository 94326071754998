import { jsx as _jsx } from "react/jsx-runtime";
import { PageModes, } from '@/objects/FCAInterfaces';
import { useToast } from '@chakra-ui/react';
import { CanvasMode, } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions, usePlatformApiRequestHandler, } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { useEffect, useState } from 'react';
import { convertAssetToCurrentAsset } from '../../conversions/AssetConversions';
import { convertSpaceToSpaceSummary, convertToSpaceSummaryWithNonStandardAssets, } from '../../conversions/SpaceConversions';
import { FacilityPageContext } from './FacilityPageContext';
export function FacilityPageProvider({ children, plans, }) {
    // setCurrentSpaces should not be used outside of the setSortedCurrentSpaces
    const [currentSpaces, setCurrentSpaces] = useState([]);
    const [selectedSpaceIdentity, setSelectedSpaceIdentity] = useState();
    const [selectedAssetIdentity, setSelectedAssetIdentity] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    const [pageMode, setPageMode] = useState(PageModes.VIEW);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [canvasDrawMode, setCanvasDrawMode] = useState(CanvasMode.VIEW);
    const [mapMode, setMapMode] = useState(false);
    const [drawSpaceShape, setDrawSpaceShape] = useState([]);
    const [drawAssetPin, setDrawAssetPin] = useState();
    const { platformApi } = useDependencies();
    const toast = useToast();
    const listSpaces = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error retrieving spaces',
        } }));
    const deleteAssetHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when deleting the asset',
        }, success: {
            label: 'Asset successfully deleted',
            enabled: true,
        } }));
    const deleteSpaceHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when deleting the space',
        }, success: {
            label: 'Space successfully deleted',
            enabled: true,
        } }));
    useEffect(() => {
        if (plans.length > 0 && !selectedPlan) {
            selectPlan(plans[0]);
        }
        if (plans.length === 0) {
            setCurrentSpaces([]);
        }
    }, [plans]);
    useEffect(() => {
        switch (pageMode) {
            case PageModes.VIEW: {
                if (canvasDrawMode !== CanvasMode.VIEW) {
                    setCanvasMode(CanvasMode.VIEW);
                }
                break;
            }
            case PageModes.ADD_SPACE: {
                setCanvasMode(CanvasMode.DRAW_SPACE_LOCATION);
                break;
            }
            case PageModes.ADD_ASSET: {
                setDrawAssetPin(undefined);
                break;
            }
        }
    }, [pageMode]);
    const setCanvasMode = (mode) => {
        setCanvasDrawMode(mode);
        switch (mode) {
            case CanvasMode.EDIT_SPACE_LOCATION:
            case CanvasMode.DRAW_SPACE_LOCATION: {
                // any space location edit mode triggered should set page mode to edit
                if (pageMode === PageModes.VIEW) {
                    setPageMode(PageModes.EDIT);
                }
                break;
            }
            case CanvasMode.DRAW_PIN: {
                // need to toggle page mode to edit if adding pin through asset view
                if (pageMode === PageModes.VIEW) {
                    setPageMode(PageModes.EDIT);
                }
                break;
            }
        }
    };
    const selectSpace = (spaceIdentity) => {
        setSelectedSpaceIdentity(spaceIdentity);
        setSelectedAssetIdentity(undefined);
    };
    const selectAsset = (assetIdentity, spaceIdentity) => {
        //TODO Make SpaceIdentity optional, use assetIdentity to get its parent space from currentSpaces, and set spaceIdentity from that.
        setSelectedAssetIdentity(assetIdentity);
        setSelectedSpaceIdentity(spaceIdentity);
    };
    const selectPlan = (plan) => {
        setSelectedSpaceIdentity(undefined);
        setSelectedAssetIdentity(undefined);
        if (plan.identity !== (selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.identity)) {
            setCurrentSpaces([]);
            setPageMode(PageModes.VIEW);
            getCurrentSpaces(plan);
        }
        setSelectedPlan(plan);
    };
    const setSortedCurrentSpaces = (updatedSpaces) => {
        const sortedSpaces = updatedSpaces.sort((a, b) => a.name.localeCompare(b.name, 'en-u-kn-true'));
        setCurrentSpaces(sortedSpaces);
    };
    const getCurrentSpaces = (plan) => {
        try {
            listSpaces
                .handleResponse(platformApi.space.getApiV1SpaceList(undefined, plan.identity), (responses) => responses.map((response) => convertToSpaceSummaryWithNonStandardAssets(response)))
                .then((spaces) => {
                setSortedCurrentSpaces(spaces || []);
            });
            return;
        }
        catch (err) {
            console.error('Could not get and or set current spaces, error:' +
                err +
                plan.identity);
        }
    };
    const deleteAsset = (assetIdentity, spaceIdentity) => {
        deleteAssetHandler
            .handleResponse(platformApi.asset.deleteApiV1AssetRemove(assetIdentity), () => { })
            .then(() => removeAsset(assetIdentity, spaceIdentity));
    };
    const deleteSpace = (spaceIdentity) => {
        deleteSpaceHandler
            .handleResponse(platformApi.space.deleteApiV1SpaceRemove(spaceIdentity), () => { })
            .then(() => removeSpace(spaceIdentity));
    };
    const removeSpace = (spaceIdentity) => {
        const updatedSpaces = currentSpaces.filter((space) => space.identity !== spaceIdentity);
        setSortedCurrentSpaces(updatedSpaces);
        setSelectedSpaceIdentity(undefined);
    };
    const removeAsset = (assetIdentity, spaceIdentity) => {
        const updatedSpaces = currentSpaces.map((space) => {
            return Object.assign(Object.assign({}, space), { assets: space.assets.filter((asset) => asset.identity !== assetIdentity) });
        });
        setSortedCurrentSpaces(updatedSpaces);
        selectSpace(spaceIdentity);
    };
    const updateCurrentSpaces = (savedSpace) => {
        const convertedSpace = convertSpaceToSpaceSummary(savedSpace);
        const spaceToUpdate = currentSpaces.find((space) => space.identity === convertedSpace.identity);
        if (spaceToUpdate) {
            convertedSpace.assets = spaceToUpdate.assets;
        }
        else {
            convertedSpace.assets = [];
        }
        const updatedSpaces = spaceToUpdate
            ? currentSpaces.map((space) => space.identity === savedSpace.spaceIdentity
                ? convertedSpace
                : space)
            : [...currentSpaces, convertedSpace];
        setSortedCurrentSpaces(updatedSpaces);
    };
    const updateCurrentSpacesWithAsset = (savedAsset) => {
        const currentSpaceIndex = currentSpaces.findIndex((space) => space.identity === savedAsset.spaceIdentity);
        if (currentSpaceIndex < 0) {
            // this should never happen
            console.error(`Unable to find current space (identity: ${savedAsset.identity}) asset was created in`);
        }
        const convertedAsset = convertAssetToCurrentAsset(savedAsset);
        const currentSpace = currentSpaces[currentSpaceIndex];
        if ((currentSpace === null || currentSpace === void 0 ? void 0 : currentSpace.assets) && currentSpace.assets.length > 0) {
            const currentAssetIndex = currentSpace.assets.findIndex((asset) => asset.identity === savedAsset.identity);
            if (currentAssetIndex < 0) {
                currentSpace.assets.push(convertedAsset);
            }
            else {
                // remove and replace with new asset
                currentSpace.assets.splice(currentAssetIndex, 1, convertedAsset);
            }
        }
        else {
            currentSpace.assets = [convertedAsset];
        }
        // update current spaces using updated space
        currentSpaces.splice(currentSpaceIndex, 1, currentSpace);
        setSortedCurrentSpaces([...currentSpaces]);
    };
    const updateCurrentSpacesWithAssetMap = (assetMap) => {
        const updatedCurrentSpaces = currentSpaces.map((space) => {
            const assetToAdd = assetMap.get(space.identity);
            if (assetToAdd) {
                if (space.assets) {
                    space.assets.push(assetToAdd);
                }
                else {
                    space.assets = [assetToAdd];
                }
            }
            return space;
        });
        setSortedCurrentSpaces(updatedCurrentSpaces);
    };
    return (_jsx(FacilityPageContext.Provider, Object.assign({ value: {
            currentSpaces,
            selectedSpaceIdentity,
            selectedAssetIdentity,
            pageMode,
            canvasMode: canvasDrawMode,
            mapMode,
            selectSpace,
            selectAsset,
            selectPlan,
            selectedPlan,
            setSelectedPlan,
            setPageMode,
            setCanvasMode,
            setMapMode,
            drawAssetPin,
            setDrawAssetPin,
            drawSpaceShape,
            setDrawSpaceShape,
            updateCurrentSpaces,
            updateCurrentSpacesWithAsset,
            updateCurrentSpacesWithAssetMap,
            removeSpace,
            removeAsset,
            isDataLoading,
            setIsDataLoading,
            deleteAsset,
            deleteSpace,
        } }, { children: children })));
}
