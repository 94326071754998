import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoIcon } from '@chakra-ui/icons';
import { Box, Heading, HStack, Link, ListItem, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger as OrigPopoverTrigger, Text, UnorderedList, } from '@chakra-ui/react';
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
import { IVerticalMarketNames, } from '@frontend/domain/models/Organization/OrganizationInterfaces';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { BsFillChatLeftTextFill } from 'react-icons/bs';
import { Link as RouterLink } from 'react-router-dom';
import { FacilityIcon, SiteIcon, SquareFeetIcon, Students, } from '../../../config/icons';
import { history } from '../../../store/store';
import { CsvFileExporter } from '../../Main/CSVfileExporter/CsvFileExporter';
export const PopoverTrigger = OrigPopoverTrigger;
var DetailTypes;
(function (DetailTypes) {
    DetailTypes["sites"] = "Site";
    DetailTypes["facilities"] = "Facility";
    DetailTypes["squareFeet"] = "Sq Ft";
    DetailTypes["numberOfStudents"] = "Students";
    DetailTypes["note"] = "Note";
})(DetailTypes || (DetailTypes = {}));
export const SiteSection = ({ site, orgId, orgVerticalMarket, }) => {
    const calculateTotalSquareFootage = (facility) => {
        var _a, _b;
        let totalSquareFootage = (_a = facility.originalSquareFootage) !== null && _a !== void 0 ? _a : 0;
        if (facility.additions) {
            for (const addition of facility.additions) {
                totalSquareFootage += (_b = addition.squareFootage) !== null && _b !== void 0 ? _b : 0;
            }
        }
        return totalSquareFootage.toString();
    };
    const renderFacilityDetail = (title, info, facility) => {
        return (_jsxs(Link, Object.assign({ display: 'flex', alignItems: 'center', as: RouterLink, replace: true, to: AppPaths.facilities.withFacilityId({
                orgIdentity: orgId,
                facilityIdentity: facility.identity,
            }), onClick: () => {
                history.push(AppPaths.facilities.base(orgId));
            } }, { children: [title === DetailTypes.sites && (_jsx(SiteIcon, { boxSize: '40px', mr: '0.5rem' })), title === DetailTypes.facilities && (_jsx(FacilityIcon, { boxSize: '40px', mr: '0.5rem' })), title === DetailTypes.squareFeet && (_jsx(SquareFeetIcon, { boxSize: '40px', mr: '0.5rem' })), title === DetailTypes.numberOfStudents && (_jsx(Students, { boxSize: '40px', mr: '0.5rem' })), title === DetailTypes.note && (_jsx(_Fragment, { children: _jsx(BsFillChatLeftTextFill, { fontSize: '25px', color: '#013b5d', style: {
                            marginRight: '0.5rem',
                            height: '40px',
                            marginLeft: '5px',
                        } }) })), _jsxs(Text, Object.assign({ color: slgqColors.primBlue }, { children: [title, ":\u00A0"] })), title === 'Note' ? (_jsx("p", Object.assign({ style: {
                        color: '#F6862A',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textAlign: 'left',
                    } }, { children: info.length > 8 ? info.substring(0, 10) + '...' : info }))) : (_jsx(Text, Object.assign({ color: slgqColors.secOrange, noOfLines: 1, textAlign: 'left' }, { children: info.length > 8 ? info.substring(0, 10) + '...' : info })))] })));
    };
    const renderFacilityDetails = (facility) => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({ flex: '1', mr: '1rem' }, { children: renderFacilityDetail(DetailTypes.facilities, facility.name, facility) })), _jsx(ListItem, Object.assign({ flex: '1', mr: '1rem' }, { children: renderFacilityDetail(DetailTypes.squareFeet, calculateTotalSquareFootage(facility), facility) })), orgVerticalMarket === IVerticalMarketNames.K12_SCHOOLS && (_jsxs(ListItem, Object.assign({ display: 'flex', flex: '1', mr: '1rem' }, { children: [renderFacilityDetail(DetailTypes.numberOfStudents, (_b = (_a = facility.numberOfOccupants) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '0', facility), _jsxs(Popover, Object.assign({ placement: 'bottom', closeOnBlur: true, trigger: 'hover' }, { children: [_jsx(PopoverTrigger, { children: _jsx(InfoIcon, { alignSelf: 'center', ml: '0.5rem !important' }) }), _jsxs(PopoverContent, Object.assign({ mb: '2rem' }, { children: [_jsx(PopoverArrow, { bg: slgqColors.primBlue }), _jsx(PopoverBody, Object.assign({ bg: slgqColors.primBlue }, { children: _jsx(Text, { children: _jsxs(Link, Object.assign({ as: 'a', href: 'https://nces.ed.gov/ccd/schoolsearch/', color: 'white', target: '_blank' }, { children: ["Search for Public Schools (ed.gov)", ' '] })) }) }))] }))] }))] }))), !facility.note ? (_jsx(ListItem, { alignSelf: 'center', flex: '1' })) : (_jsx(ListItem, Object.assign({ alignSelf: 'center', flex: '1' }, { children: renderFacilityDetail(DetailTypes.note, facility.note, facility) })))] }));
    };
    const renderFacilities = () => {
        return (_jsx(_Fragment, { children: (site === null || site === void 0 ? void 0 : site.facilities) &&
                (site === null || site === void 0 ? void 0 : site.facilities.map((facility, i) => {
                    return (_jsxs(HStack, Object.assign({ px: '20px', py: '10px', w: '100%', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', borderRadius: '8px', fontWeight: 600, mb: '20px' }, { children: [_jsx(UnorderedList, Object.assign({ listStyleType: 'none', w: '100%', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }, { children: renderFacilityDetails(facility) })), _jsx(Box, { children: orgId && (_jsx(CsvFileExporter, { facilityId: facility.identity, facilityName: facility.name, organizationIdentity: orgId })) })] }), `facility_item_${i}`));
                })) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(HStack, Object.assign({ justifyContent: 'space-between', mb: '1rem' }, { children: _jsx(Heading, Object.assign({ as: 'h2', variant: variants.h2, textAlign: 'left', mb: '30px', margin: '0' }, { children: site === null || site === void 0 ? void 0 : site.name })) })), renderFacilities()] }));
};
