var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { HStack, Textarea } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { addNote } from '../../../services/api/facilityAPI';
export const FacilityNotes = ({ facilityId, note, }) => {
    const [noteTerm, setNoteTerm] = useState(note !== null && note !== void 0 ? note : '');
    useEffect(() => {
        const saveNote = () => __awaiter(void 0, void 0, void 0, function* () {
            yield addNote(facilityId, { note: noteTerm });
        });
        const timeoutId = setTimeout(() => {
            if (noteTerm) {
                saveNote();
            }
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [noteTerm]);
    return (_jsx(HStack, Object.assign({ h: '100%' }, { children: _jsx(Textarea, { defaultValue: note, w: '100%', h: '100%', p: '5px', mt: '0 !important', placeholder: 'Type here to write your notes', onChange: (e) => setNoteTerm(e.target.value) }) })));
};
