import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { generateTextColor } from '../../../../../config/FCAHelperFunctions';
export const TagCard = ({ name, color, handleTagDelete, tagToDelete, canEdit, }) => {
    return (_jsxs(Flex, Object.assign({ width: 'auto', alignItems: 'center', bg: color, borderRadius: '2px', p: '0px 5px', ml: '10px', mb: '10px', onClick: () => handleTagDelete &&
            tagToDelete &&
            canEdit &&
            handleTagDelete(tagToDelete), cursor: canEdit ? 'pointer' : 'auto' }, { children: [_jsx(Text, Object.assign({ fontSize: '12px', fontWeight: '500', color: generateTextColor(color), mr: '10px' }, { children: name })), tagToDelete && canEdit && (_jsx(CloseIcon, { fontSize: '8px', color: generateTextColor(color), "data-testid": 'tag-close' }))] })));
};
