import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { slgqColors } from '@frontend/design-system/theme/theme';
import { variants } from '@frontend/design-system/theme/theme';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, IconButton, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { DynamicInput } from '../../Inputs/DynamicInput';
export const CollapsibleList = ({ data, options, checkboxFunction, }) => {
    const [showCollapsibleList, setShowCollapsibleList] = useState(true);
    const showHideDropdownList = () => {
        setShowCollapsibleList(!showCollapsibleList);
    };
    return (_jsxs(Box, Object.assign({ style: {
            width: '100%',
        } }, { children: [_jsxs(Text, Object.assign({ justifyItems: 'flex-start' }, { children: [options.class, _jsx(IconButton, { onClick: showHideDropdownList, onTouchEnd: showHideDropdownList, "aria-label": `expand/collapse checklist for ${options.class}`, icon: !showCollapsibleList ? (_jsx(ChevronDownIcon, { color: slgqColors.secGrey, boxSize: 6 })) : (_jsx(ChevronUpIcon, { color: slgqColors.secGrey, boxSize: 6 })), variant: variants.transparentBtn, boxSize: '32px' })] })), showCollapsibleList && (_jsx(VStack, Object.assign({ style: {
                    width: '100%',
                    paddingLeft: '15px',
                } }, { children: options &&
                    options.propertiesToCopy.map((property, i) => {
                        return (_jsx(DynamicInput, { data: data.includes(property), editMode: true, inputType: 'checkbox', label: property.label, handleInputChange: () => {
                                checkboxFunction(property);
                            } }, `${property.label},${i}`));
                    }) })))] })));
};
