import { FacilityMapTypes } from '../objects/FCAHelperInterfaces';
import { AssetClassType, } from '../objects/FCAInterfaces';
import { tagColors } from './constants';
export const getHeadAssetByType = (type, assets) => {
    return assets.find((asset) => AssetClassType.STANDARD === asset.indicator &&
        type === asset.assetType && // This should be assetClass
        (type === asset.name || `${type} 1` === asset.name));
};
export const getHvacAssetByType = (type, assets) => {
    return assets.find((asset) => AssetClassType.FACILITY === asset.indicator &&
        type === FacilityMapTypes.HVAC);
};
// because field typing is any for form versatility we need to handle all input types
export const convertFtDecIntoFtIn = (ftDecimal) => {
    if (typeof ftDecimal === 'number') {
        return {
            feet: ftDecimal.toString(),
            inches: '',
        };
    }
    else if (typeof ftDecimal === 'string' && ftDecimal !== '') {
        const ftDecimalArray = ftDecimal.split(',');
        const feet = ftDecimalArray[0];
        const inches = ftDecimalArray[1];
        return {
            feet: isStringAnInt(feet) ? feet : '',
            inches: isStringAnInt(inches) ? inches : '',
        };
    }
    else {
        return {
            feet: '',
            inches: '',
        };
    }
};
const isStringAnInt = (strVal) => {
    return !isNaN(parseInt(strVal));
};
export const getListOfUniqueValues = (data, key) => {
    const uniqueValuesSet = new Set();
    data &&
        data.forEach((item) => {
            const value = item[key];
            if (value) {
                uniqueValuesSet.add(value);
            }
        });
    return [...uniqueValuesSet]
        .map((item) => ({
        identity: item,
        name: String(item),
    }))
        .sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
};
const checkValues = (formValues, dropdownValues, key) => {
    var _a;
    const selectedValues = Array.isArray(formValues)
        ? formValues
        : [formValues];
    return ((_a = selectedValues
        .map((formValue) => {
        return dropdownValues.some((dropdownValue) => {
            return dropdownValue[key] === (formValue === null || formValue === void 0 ? void 0 : formValue.value);
        })
            ? formValue
            : undefined;
    })
        .filter((value) => value !== undefined)) !== null && _a !== void 0 ? _a : []);
};
export const resetFilterMoveForm = (key, resetField, form, dropdowns) => {
    switch (key) {
        case 'Facility':
            if (form['Space Construction Year']) {
                resetField('Space Construction Year', {
                    defaultValue: checkValues(form['Space Construction Year'], dropdowns['Space Construction Year'], 'constrYear'),
                });
            }
            if (form.Space) {
                resetField('Space', {
                    defaultValue: checkValues(form['Space'], dropdowns.Space, 'identity'),
                });
            }
            if (form.Category) {
                resetField('Category', {
                    defaultValue: checkValues(form['Category'], dropdowns.Category, 'category'),
                });
            }
            if (form['Asset Type']) {
                resetField('Asset Type', {
                    defaultValue: checkValues(form['Asset Type'], dropdowns['Asset Type'], 'assetType'),
                });
            }
            if (form['Install Year']) {
                resetField('Install Year', {
                    defaultValue: checkValues(form['Install Year'], dropdowns['Install Year'], 'installYear'),
                });
            }
            if (form.Asset) {
                resetField('Asset', {
                    defaultValue: checkValues(form.Asset, dropdowns.Asset, 'identity'),
                });
            }
            break;
        case 'Space Construction Year':
            if (form.Space) {
                resetField('Space', {
                    defaultValue: checkValues(form['Space'], dropdowns.Space, 'identity'),
                });
            }
            if (form.Category) {
                resetField('Category', {
                    defaultValue: checkValues(form['Category'], dropdowns.Category, 'category'),
                });
            }
            if (form['Asset Type']) {
                resetField('Asset Type', {
                    defaultValue: checkValues(form['Asset Type'], dropdowns['Asset Type'], 'assetType'),
                });
            }
            if (form['Install Year']) {
                resetField('Install Year', {
                    defaultValue: checkValues(form['Install Year'], dropdowns['Install Year'], 'installYear'),
                });
            }
            if (form.Asset) {
                resetField('Asset', {
                    defaultValue: checkValues(form.Asset, dropdowns.Asset, 'identity'),
                });
            }
            break;
        case 'Space':
            if (form.Category) {
                resetField('Category', {
                    defaultValue: checkValues(form['Category'], dropdowns.Category, 'category'),
                });
            }
            if (form['Asset Type']) {
                resetField('Asset Type', {
                    defaultValue: checkValues(form['Asset Type'], dropdowns['Asset Type'], 'assetType'),
                });
            }
            if (form['Install Year']) {
                resetField('Install Year', {
                    defaultValue: checkValues(form['Install Year'], dropdowns['Install Year'], 'installYear'),
                });
            }
            if (form.Asset) {
                resetField('Asset', {
                    defaultValue: checkValues(form.Asset, dropdowns.Asset, 'identity'),
                });
            }
            break;
        case 'Category':
            if (form['Asset Type']) {
                resetField('Asset Type', {
                    defaultValue: checkValues(form['Asset Type'], dropdowns['Asset Type'], 'assetType'),
                });
            }
            if (form['Install Year']) {
                resetField('Install Year', {
                    defaultValue: checkValues(form['Install Year'], dropdowns['Install Year'], 'installYear'),
                });
            }
            if (form.Asset) {
                resetField('Asset', {
                    defaultValue: checkValues(form['Asset'], dropdowns.Asset, 'identity'),
                });
            }
            break;
        case 'Asset Type':
            if (form['Install Year']) {
                resetField('Install Year', {
                    defaultValue: checkValues(form['Install Year'], dropdowns['Install Year'], 'installYear'),
                });
            }
            if (form.Asset) {
                resetField('Asset', {
                    defaultValue: checkValues(form.Asset, dropdowns.Asset, 'identity'),
                });
            }
            break;
        case 'Install Year':
            if (form.Asset) {
                resetField('Asset', {
                    defaultValue: checkValues(form.Asset, dropdowns.Asset, 'identity'),
                });
            }
            break;
    }
};
export const generateTextColor = (color) => {
    for (const key in tagColors.LIGHT) {
        if (tagColors.LIGHT[key] === color) {
            return 'black';
        }
    }
    return 'white';
};
