import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import { useFcaPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useEffect, useState } from 'react';
import { appValues } from '../../../../config/constants';
import { AssetProvider } from '../../../../contexts/Assets/AssetProvider';
import { useFacilityContext } from '../../../../contexts/Facilities/FacilityPageContext';
import { PhotoProvider } from '../../../../contexts/Photo/PhotoProvider';
import { SpaceProvider } from '../../../../contexts/Spaces/SpaceProvider';
import { TemplateProvider } from '../../../../contexts/Template/TemplateProvider';
import { PageModes } from '../../../../objects/FCAInterfaces';
import { Sidebar } from '../Sidebar';
import { SidebarAsset } from './Asset/SidebarAsset';
import { SidebarSpace } from './Space/SidebarSpace';
export const DetailsSidebar = ({ orgIdentity, facilityIdentity, }) => {
    const { pageMode, selectedAssetIdentity, currentSpaces, selectedSpaceIdentity, } = useFacilityContext();
    const { canEdit } = useFcaPermissions(facilityIdentity);
    const currentSpace = currentSpaces.find((space) => selectedSpaceIdentity === space.identity);
    const isEditing = pageMode === PageModes.EDIT;
    const isAddingAsset = pageMode === PageModes.ADD_ASSET;
    const isAddingSpace = pageMode === PageModes.ADD_SPACE;
    const [sidebarCollapseOverride, setOverride] = useState(false);
    useEffect(() => {
        setOverride(isAddingAsset || isAddingSpace || isEditing);
    }, [isAddingAsset, isAddingSpace, isEditing]);
    const sidebarToDisplay = (pageMode, selectedSpaceIdentity, selectedAssetIdentity) => {
        switch (pageMode) {
            case PageModes.ADD_SPACE: {
                // show space
                return 'SPACE';
            }
            case PageModes.VIEW:
            case PageModes.EDIT: {
                if (selectedSpaceIdentity) {
                    return selectedAssetIdentity ? 'ASSET' : 'SPACE';
                }
                else {
                    return 'NONE';
                }
            }
            case PageModes.ADD_ASSET: {
                return 'ASSET';
            }
            default:
                return 'NONE';
        }
    };
    const renderSidebarDetails = () => {
        if (!facilityIdentity) {
            return _jsx(_Fragment, {});
        }
        switch (sidebarToDisplay(pageMode, selectedSpaceIdentity, selectedAssetIdentity)) {
            case 'SPACE': {
                return (_jsx(SpaceProvider, Object.assign({ spaceIdentity: selectedSpaceIdentity, facilityIdentity: facilityIdentity }, { children: _jsx(PhotoProvider, Object.assign({ orgIdentity: orgIdentity, facilityIdentity: facilityIdentity, defaultFilters: {
                            spaceIdentities: [
                                {
                                    value: selectedSpaceIdentity,
                                },
                            ],
                        } }, { children: _jsx(SidebarSpace, { canEdit: canEdit, orgIdentity: orgIdentity }) })) })));
            }
            case 'ASSET': {
                // redundant protection for empty space identity (ts linting fix)
                //   should never get undefined here based on sidebarToDisplay logic
                return selectedSpaceIdentity ? (_jsx(AssetProvider, Object.assign({ spaceIdentity: selectedSpaceIdentity, assetIdentity: selectedAssetIdentity }, { children: _jsx(SidebarAsset, { orgIdentity: orgIdentity, facilityId: facilityIdentity, spaceId: selectedSpaceIdentity, canEdit: canEdit }) }))) : (_jsx(_Fragment, {}));
            }
            default: {
                return _jsx(_Fragment, {});
            }
        }
    };
    return (_jsx(TemplateProvider, Object.assign({ organizationId: orgIdentity, spaceCategory: currentSpace === null || currentSpace === void 0 ? void 0 : currentSpace.category, isStandard: !selectedAssetIdentity && !isAddingAsset }, { children: _jsx(Sidebar, Object.assign({ override: sidebarCollapseOverride, placement: 'right', width: appValues.SIDEBAR_WIDTH_DETAILS }, { children: _jsx(HStack, Object.assign({ flexDirection: 'column', justifyContent: 'space-between', h: '100%', paddingBottom: '1rem' }, { children: renderSidebarDetails() })) })) })));
};
