import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, HStack, Image, Link } from '@chakra-ui/react';
import { Loading } from '@frontend/design-system/components/Loading/Loading';
import { appColors } from '@frontend/design-system/theme/constants';
import { variants } from '@frontend/design-system/theme/theme';
import { AppPaths, AppRoutes, } from '@frontend/shared/src/components/Routing/RouteConstants';
import { Link as RouterLink } from 'react-router-dom';
import { appValues } from '../../config/constants';
import logo from '../../images/Logo_OrangeWhite.png';
export const FacilityPageHeader = ({ orgId, orgName, siteName, facilityName, }) => {
    return (_jsxs(HStack, Object.assign({ bg: 'slgq.primBlue', color: 'white', h: `${appValues.HEADER_HEIGHT_MAIN}px`, position: 'relative' }, { children: [_jsx(Link, Object.assign({ as: RouterLink, replace: true, to: orgId
                    ? AppRoutes.myFacilities(orgId)
                    : AppPaths.organization.base }, { children: _jsx(Image, { src: logo, alt: 'logo', px: '32px' }) })), _jsx(Box, Object.assign({ alignSelf: 'center', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }, { children: !orgName || !siteName || !facilityName ? (_jsx(Loading, { color: appColors.BACKGROUND_LIGHT_GRAY })) : (_jsx(Link, Object.assign({ as: RouterLink, replace: true, to: orgId
                        ? AppRoutes.myFacilities(orgId)
                        : AppPaths.organization.base }, { children: _jsxs(Heading, Object.assign({ as: 'h1', fontWeight: 400, variant: variants.regSizeH }, { children: [orgName, " / ", siteName, " / ", facilityName] })) }))) }))] })));
};
