import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Center, Flex, Stack } from '@chakra-ui/react';
import { Loading } from '@frontend/design-system/components/Loading/Loading';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { PageLayout } from '../../../components/layouts/PageLayout';
import { appValues } from '../../../config/constants';
import { SiteSection } from './SiteSection';
export const SitesPage = () => {
    const { selectedOrganizationIdentity, isLoading: orgListLoading } = useOrganizationSummaries();
    const { organizationDetails: org, isLoading: orgLoading } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const sites = org === null || org === void 0 ? void 0 : org.sites;
    const canRenderSiteSection = sites && sites.length > 0;
    const renderSites = () => {
        return (_jsx(_Fragment, { children: canRenderSiteSection &&
                org &&
                sites.map((site, i) => {
                    return (_jsx(SiteSection, { site: site, orgId: org.identity, orgVerticalMarket: org.verticalMarket }, i));
                }) }));
    };
    return (_jsx(PageLayout, Object.assign({ title: org === null || org === void 0 ? void 0 : org.name }, { children: _jsx(Flex, Object.assign({ as: 'main', h: `calc(100vh - ${appValues.HEADER_HEIGHT}px)`, alignItems: 'flex-start', p: '50px 50px 0px 50px' }, { children: orgListLoading || orgLoading ? (_jsx(Center, Object.assign({ width: '100%', height: '100%', overflowY: 'hidden', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: '100' }, { children: _jsx(Loading, {}) }))) : (_jsx(Stack, Object.assign({ width: '100%', gap: '0px' }, { children: renderSites() }))) })) })));
};
