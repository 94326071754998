import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Center, Spinner, Text } from '@chakra-ui/react';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { PageLayout } from '../../components/layouts/PageLayout';
// 4352 TODO: This page should be switched to be an UnauthorizedOrg page and utilized in App.tsx similar to how it is used in MSIQ and CP apps
export const AccessDenied = () => {
    var _a;
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails, isLoading } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const concierge = organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.clientConcierge;
    return (_jsx(PageLayout, Object.assign({ title: (_a = organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.name) !== null && _a !== void 0 ? _a : 'No App Access' }, { children: _jsx(Box, Object.assign({ m: '0px 50px' }, { children: isLoading ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: 'blue.500' }) }))) : (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsxs(Box, Object.assign({ textAlign: 'center' }, { children: [_jsx(Text, Object.assign({ mb: '2' }, { children: "At this moment, you do not have access for this organization." })), _jsx(Text, { children: concierge
                                ? `Please contact your client concierge, ${concierge.firstName} ${concierge.lastName} at ${concierge.email}`
                                : 'Please contact your client concierge.' })] })) }))) })) })));
};
