import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox, ListItem } from '@chakra-ui/react';
import { useState } from 'react';
import { slgqColors } from '@frontend/design-system/theme/theme';
export const FilterCheckbox = ({ handleChange, children, initialValue, }) => {
    const [isChecked, setIsChecked] = useState(initialValue);
    return (_jsx(ListItem, { children: _jsx(Checkbox, Object.assign({ iconColor: slgqColors.secOrange, isChecked: isChecked, onChange: () => {
                setIsChecked(!isChecked);
                handleChange(!isChecked);
            } }, { children: children })) }));
};
