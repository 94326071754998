import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { slgqColors, variants } from '@frontend/design-system/theme/theme';
import { useCopyPasteContext } from '@/contexts/CopyPaste/CopyPasteContext';
import { Box, Button, ButtonGroup, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, } from '@chakra-ui/react';
import { DynamicInput } from '../../Inputs/DynamicInput';
import { CollapsibleList } from './CollapsibleList';
export const CopySpaceModal = ({ isOpen, onClose, handleClose, handleSelectSpaces, }) => {
    const { togglePropertyDetail, clearPropertiesToCopy, currentSpaceCopy, copyDetails, } = useCopyPasteContext();
    const renderSpaceProperties = () => {
        return (_jsxs(Box, Object.assign({ style: { flex: 1 } }, { children: [_jsx(Text, Object.assign({ fontWeight: 600, color: 'slgq.primBlue', variant: variants.regSizeH, paddingTop: '10px', paddingBottom: '10px' }, { children: "Space" })), _jsxs(VStack, Object.assign({ style: {
                        height: '300px',
                        maxWidth: '280px',
                        overflowY: 'auto',
                        border: '1px solid #C0C2C1',
                        borderRadius: '4px',
                        padding: '10px',
                    } }, { children: [currentSpaceCopy &&
                            currentSpaceCopy.spaceDetail.propertiesToCopy.map((spaceProperty, key) => {
                                if (spaceProperty) {
                                    return (_jsx(DynamicInput, { data: copyDetails.includes(spaceProperty), editMode: true, inputType: 'checkbox', label: spaceProperty.label, handleInputChange: () => {
                                            togglePropertyDetail(spaceProperty);
                                        } }, key));
                                }
                            }), currentSpaceCopy &&
                            currentSpaceCopy.spaceIEQ &&
                            currentSpaceCopy.spaceIEQ.propertiesToCopy.length >
                                0 && (_jsx(CollapsibleList, { data: copyDetails, options: currentSpaceCopy.spaceIEQ, checkboxFunction: togglePropertyDetail }))] }))] })));
    };
    const renderStandardAssetProperties = () => {
        var _a;
        return (_jsxs(Box, Object.assign({ style: { flex: 1 } }, { children: [_jsx(Text, Object.assign({ fontWeight: 600, color: 'slgq.primBlue', variant: variants.regSizeH, paddingTop: '10px', paddingBottom: '10px' }, { children: "Standard Assets" })), _jsx(VStack, Object.assign({ style: {
                        height: '300px',
                        maxWidth: '280px',
                        overflowY: 'auto',
                        border: '1px solid #C0C2C1',
                        borderRadius: '4px',
                        padding: '10px',
                    } }, { children: currentSpaceCopy &&
                        ((_a = currentSpaceCopy.standardAssets) === null || _a === void 0 ? void 0 : _a.map((standardAsset, key) => {
                            if (standardAsset &&
                                standardAsset.propertiesToCopy.length > 0) {
                                return (_jsx(CollapsibleList, { data: copyDetails, options: standardAsset, checkboxFunction: togglePropertyDetail }, key));
                            }
                        })) }))] })));
    };
    const renderFacilityAssets = () => {
        return (_jsxs(Box, Object.assign({ style: { flex: 1 } }, { children: [_jsx(Text, Object.assign({ fontWeight: 600, color: 'slgq.primBlue', variant: variants.regSizeH, paddingTop: '10px', paddingBottom: '10px' }, { children: "Facility Assigned Assets" })), _jsx(VStack, Object.assign({ style: {
                        height: '300px',
                        maxWidth: '280px',
                        overflowY: 'auto',
                        border: '1px solid #C0C2C1',
                        borderRadius: '4px',
                        padding: '10px',
                    } }, { children: currentSpaceCopy &&
                        currentSpaceCopy.facilityAssets.map((facilityAsset, key) => {
                            return (_jsx(DynamicInput, { data: copyDetails.includes(facilityAsset), editMode: true, inputType: 'checkbox', label: facilityAsset.label, handleInputChange: () => {
                                    togglePropertyDetail(facilityAsset);
                                } }, key));
                        }) }))] })));
    };
    return (_jsxs(Modal, Object.assign({ closeOnEsc: false, closeOnOverlayClick: false, isOpen: isOpen, isCentered: true, onClose: onClose, size: 'xl' }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ padding: '30px', maxH: '600px', maxW: '1000px' }, { children: [_jsx(ModalHeader, Object.assign({ color: slgqColors.secGrey, fontSize: '36px', fontWeight: 400, padding: '0px' }, { children: "Copy" })), _jsxs(ModalBody, Object.assign({ p: '0px', mt: '20px', mb: '30px' }, { children: [_jsx(Text, { children: "Select the spaces or assets that you would like to copy. Then click the \"Select Spaces\" button to choose the spaces that you would like to paste into (photos are not included in copy & paste)." }), _jsxs(HStack, Object.assign({ justifyContent: 'space-between' }, { children: [renderSpaceProperties(), renderStandardAssetProperties(), renderFacilityAssets()] }))] })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: '100%' }, { children: [_jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ fontSize: '14px', onClick: handleClose, onTouchEnd: handleClose, variant: variants.transparentRedBtn, type: 'button' }, { children: "Cancel" })), _jsx(Button, Object.assign({ paddingLeft: '20px', fontSize: '14px', onClick: clearPropertiesToCopy, onTouchEnd: clearPropertiesToCopy, variant: variants.fcaTransparentBlueBtn, type: 'button' }, { children: "Clear all" }))] }), _jsx(Button, Object.assign({ color: slgqColors.primWhite, fontSize: '14px', onClick: handleSelectSpaces, onTouchEnd: handleSelectSpaces, type: 'button', isDisabled: copyDetails.length > 0 ? false : true, variant: variants.fcaBlueBtn, _hover: { variant: variants.fcaBlueBtn } }, { children: "Select Spaces" }))] })) }))] }))] })));
};
