var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCopyPasteContext } from '@/contexts/CopyPaste/CopyPasteContext';
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { useFloorplanCanvasShape } from '@/pages/Main/Canvas/useFloorplanCanvasShapes';
import { getImageUrl, toBlobStoreContainerUrl } from '@/utils/photoFileUtils';
import { useEffect, useState } from 'react';
import { SelectSpacesModal } from './SelectSpacesModal';
export const CopyAsset = ({ assetIdentity, isOpen, onClose, plans, }) => {
    const { facilitySpaces, clearSelectedSpaces, pasteAssetToSelectedSpaces, toggleSelectedSpace, selectedSpaceIdentities, isPasteAssetToSpacesLoading, } = useCopyPasteContext();
    const { getViewOnlyShape } = useFloorplanCanvasShape();
    const { selectedPlan, updateCurrentSpacesWithAssetMap } = useFacilityContext();
    const [imageString, setImageString] = useState('');
    const [plan, setPlan] = useState(selectedPlan);
    useEffect(() => {
        const loadImageUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const url = yield getImageUrl(toBlobStoreContainerUrl((_a = plan === null || plan === void 0 ? void 0 : plan.planFileName) !== null && _a !== void 0 ? _a : ''));
            url ? setImageString(url) : console.log('Failed');
        });
        isOpen && loadImageUrl();
        planUpdateHandler(plan);
    }, [plan, isOpen]);
    const submitAssetCopyToSpaces = () => __awaiter(void 0, void 0, void 0, function* () {
        const returnedAssets = yield pasteAssetToSelectedSpaces(assetIdentity);
        if (returnedAssets) {
            updateCurrentSpacesWithAssetMap(returnedAssets);
            setPlan(selectedPlan);
        }
        onCloseHandler();
    });
    const onCloseHandler = () => {
        clearSelectedSpaces();
        setPlan(selectedPlan);
        onClose();
    };
    const planUpdateHandler = (plan) => {
        plan && setPlan(plan);
    };
    const isSpaceSelected = (space) => {
        return !!selectedSpaceIdentities.find((id) => space.identity === id);
    };
    const getShapes = (spaces) => {
        return spaces.map((space) => getViewOnlyShape(space, isSpaceSelected));
    };
    const currentSpaces = facilitySpaces.filter((space) => space.floorplanIdentity === (plan === null || plan === void 0 ? void 0 : plan.identity));
    return (_jsx(SelectSpacesModal, { floorPlanImage: imageString, handleSubmit: submitAssetCopyToSpaces, isOpen: isOpen, onClose: onCloseHandler, onClear: clearSelectedSpaces, handleBackOrCancel: { label: 'Cancel', handler: onCloseHandler }, onSelect: (space) => {
            toggleSelectedSpace(space.identity);
        }, shapes: getShapes(currentSpaces), spaces: currentSpaces, selectedSpaces: selectedSpaceIdentities, plans: plans, selectedPlan: plan, onPlanSelect: setPlan, isLoading: isPasteAssetToSpacesLoading }));
};
