import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { ReassessModal } from './ReassessModal';
export const ReassessButton = ({ children, reassessConfig, type, }) => {
    const { isOpen: isReassessModal, onOpen: onOpenReassessModal, onClose: onCloseReassessModal, } = useDisclosure();
    return (_jsxs(HStack, Object.assign({ w: '100%' }, { children: [_jsx(HStack, Object.assign({ position: 'relative' }, { children: _jsx(Button, { onClick: (e) => {
                        e.stopPropagation();
                        onOpenReassessModal();
                    }, rightIcon: _jsx(AddIcon, {}), variant: variants.transparentBtn, position: 'absolute', top: 0, right: 0, "data-testid": 'reassess-modal-open' }) })), _jsx(ReassessModal, { children: children, isOpen: isReassessModal, onClose: onCloseReassessModal, reassessConfig: reassessConfig, type: type })] })));
};
